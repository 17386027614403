import { Grid, Paper } from "@mui/material"; //Layout and styling
import Library from "@mui/icons-material/LocalLibrary"; //Library icon
import Chain from "@mui/icons-material/Link"; //Link or chain icon
import FLF from "../ForeignLists/FLF"; //
import YakinTabFct from "../YakinTab/YakinTabFct";
import NewLoginForm from "../User/NewLoginForm";
import React, { useState, useEffect, useRef, useContext } from "react"; //React hooks for state management, side effects, referencing DOM elements, accessing context values
import { WordContext } from "../Contexts/WordContext"; //React context to provide the "word" value and a function to set it
import Bricks from "../../Images/bricks.svg";
import Dots2 from "../../Images/pattern-dots2.svg";
import Foldable from "../Common/Foldable";
import Tutorial from "../Common/Tutorial";
import { TutorialContext } from "../Common/TutorialContext";
import { useTranslation } from 'react-i18next';

// Provide a structured layout to display a login form, thesaurus chain, English library section
function SupportingDataColumn() {
  const { t, i18n } = useTranslation();
  const { word, setWord } = useContext(WordContext);
  //console.log("SupportingDataColumn");
  const {currentStep, tutorialVisible, handleNextStep, handleCloseTutorial, currentFocusName} = useContext(TutorialContext);

  // Refs for the components (for tutorial)
  const yakinTabRef = useRef(null);
  const flfRef = useRef(null);

  var content = (
    <Paper
      className="main-container paper"
      sx={{
        backgroundImage: `url(${Dots2})`,
        backgroundSize: "100em", // Set to 'auto' or a specific size for repeating
        backgroundRepeat: "repeat", // Options: 'repeat', 'repeat-x', 'repeat-y', 'no-repeat'
        backgroundPosition: "top left", // Starting position of the background image
      }}
      elevation={3}
    >
      {/*<NewLoginForm />*/}
      {/* Thesaurus Chain Section */}
      <Foldable title={t("supporting.ThesaurusTitle")} ComponentIcon={<Chain />}>
      <div ref={yakinTabRef}>
          <YakinTabFct word={word} />
        </div>
        {tutorialVisible && currentFocusName === "SupportingData" && currentStep === 1 && (
          <Tutorial
            refElement={yakinTabRef}
            step={1}
            currentStep={currentStep}
            message={t("tutorial.Supporting1")}
            onNext={handleNextStep}
          onClose={handleCloseTutorial}
          />
        )}
      </Foldable>

      {/* English Library Section */}
      <Foldable title={t("supporting.EngLibTitle")} ComponentIcon={<Library />}>
      <div ref={flfRef}>
          <FLF word={word} />
        </div>
        {tutorialVisible && currentFocusName === "SupportingData" && currentStep === 2 && (
          <Tutorial
            refElement={flfRef}
            step={2}
            currentStep={currentStep}
            message={t("tutorial.Supporting2")}
            onNext={handleNextStep}
            onClose={handleCloseTutorial}
          />
        )}
      </Foldable>
    </Paper>
  );

  return content;
}

export default SupportingDataColumn;