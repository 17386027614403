import React from "react";
import { useTranslation } from "react-i18next";

function UILangSwitcher({ interfaceLanguage, onInterfaceLanguageChange }) {
  const { t } = useTranslation();

  return (
    <div className="language-switcher flex-row">
      <label className="language-switch" htmlFor="language-switch" style={{color: "#e78700"}}>
        {t("selectUILanguage")}:&nbsp;
      </label>
      <select
        id="language-switch"
        value={interfaceLanguage}
        onChange={(e) => onInterfaceLanguageChange(e.target.value)}
      >
        <option value="tr">{t("languageTurkish")}</option>
        <option value="en">{t("languageEnglish")}</option>
      </select>
    </div>
  );
}

export default UILangSwitcher;
