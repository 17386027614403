import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Confetti from "react-confetti";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "../../styles/gamesContainer.css";
import axios from "axios";
import MAPIURLMaker from "../MAPIURLMaker";
import KeyboardComponent from "./KeyboardComponent";
import GameRules from "./GameRules";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import BackspaceIcon from "@mui/icons-material/Backspace";
import GameMessage from "./GameMessage";
import GameEndScreen from "./GameEndScreen";
import deleteIcon from "../../Images/delete_message.png";
import UILangSwitcher from "./UILangSwitcher";

const WordWander = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("tr");
  const [interfaceLanguage, setInterfaceLanguage] = useState("tr");
  const [level, setLevel] = useState("impossible");
  const [letters, setLetters] = useState([]);
  const [input, setInput] = useState("");
  const [foundWords, setFoundWords] = useState([]);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(120);
  const [gameOver, setGameOver] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [endMessage, setEndMessage] = useState(null);
  const [popupMessage, setPopupMessage] = useState(null);
  const [popupType, setPopupType] = useState("neutral");
  const [gameStarted, setGameStarted] = useState(false);
  const [showGameRules, setShowGameRules] = useState(false);
  const inputRef = useRef(null);

  // Games rules for Word Wander
  const gameRulesText = (
    <div>
      <h2>{i18n.t("wordWander.gameRules.welcome")}</h2>
      <p>{i18n.t("wordWander.gameRules.goal")}</p>
      <h3>{i18n.t("wordWander.gameRules.howToPlay")}</h3>
      <ul>
        <li>{i18n.t("wordWander.gameRules.rule1")}</li>
        <li>{i18n.t("wordWander.gameRules.rule2")}</li>
        <li>{i18n.t("wordWander.gameRules.rule3")}</li>
        <li> {i18n.t("wordWander.gameRules.rule4")}</li>
        <li>{i18n.t("wordWander.gameRules.rule5")}</li>
      </ul>
      <h3> {i18n.t("wordWander.gameRules.scoringSystem")}</h3>
      <ul>
        <li>{i18n.t("wordWander.gameRules.scoreRule1")}</li>
        <li>{i18n.t("wordWander.gameRules.scoreRule2")}</li>
        <li>{i18n.t("wordWander.gameRules.scoreRule3")}</li>
        <li>{i18n.t("wordWander.gameRules.scoreRule4")}</li>
        <li>{i18n.t("wordWander.gameRules.scoreRule5")}</li>
      </ul>
      <p>{i18n.t("wordWander.gameRules.goodLuck")}</p>
    </div>
  );

  useEffect(() => {
    startGame();
  }, [language, level]);

  useEffect(() => {
    if (gameStarted && timeLeft > 0 && !gameOver) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      setGameOver(true);
      if (score >= 500) {
        setShowConfetti(true);
      }
      setEndMessage(
        score >= 500 ? i18n.t("wordWander.win") : i18n.t("wordWander.lose"),
      );
    }
  }, [gameStarted, timeLeft, gameOver, score]);

  // Fetch random letters from the backend
  const startGame = async () => {
    try {
      let url = "";

      if (language === "en") {
        setLanguage("en");
        url = MAPIURLMaker("games/random-letters") + `?lang=EN`;
      } else {
        url = MAPIURLMaker("games/random-letters") + `?lang=TR`;
      }
      const response = await axios.get(url);
      setLetters(response.data);
    } catch (error) {
      console.error("Error fetching letters:", error);
    }

    setInput("");
    setFoundWords([]);
    setScore(0);
    setTimeLeft(120);
    setGameOver(false);
    setShowConfetti(false);
    setEndMessage(null);
    setPopupMessage(null);
    inputRef.current?.focus();
  };

  useEffect(() => {
      setInterfaceLanguage(language);
    }, [language]);

  const handleLanguageChange = (newLang) => {
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const handleInterfaceLanguageChange = (newLang) => {
    setInterfaceLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const handleLevelChange = (newLevel) => {
    setLevel(newLevel);
    console.log("Selected Level:", newLevel);
    resetGame();
  };

  const resetGame = () => {
    setGameStarted(true);
    setTimeLeft(120);
    setInput("");
    setFoundWords([]);
    setScore(0);
    setEndMessage(null);
    setPopupMessage(null);
    setGameOver(false);
    setShowConfetti(false);
    inputRef.current?.focus();
  };

  // Check if the input is made up of the given letters
  const isValidWord = (word, letters) => {
    for (let char of word) {
      if (!letters.includes(char)) {
        return false;
      }
    }
    return true;
  };

  const calculateScore = (word) => {
    const length = word.length;
    if (length === 2) return 10;
    if (length === 3) return 50;
    if (length === 4) return 100;
    if (length === 5) return 150;
    if (length >= 6) return 500;
    return 0;
  };

  // Check if the word is valid by making an API call to the backend
  const handleSubmitWord = async () => {
    if (!input.trim()) {
      // Check if input is empty or only whitespace
      setPopupMessage(i18n.t("wordWander.emptyInput")); // Show a warning message
      setTimeout(() => setPopupMessage(""), 1000); // Clear the message after 1 second
      inputRef.current?.focus();
      return;
    }
    console.log("User submitted input - WORDWANDER:", input);
    if (!isValidWord(input, letters)) {
      setPopupMessage(i18n.t("wordWander.warning"));
      setTimeout(() => setPopupMessage(""), 1000);
      setInput("");
      inputRef.current?.focus();
      return;
    }

    try {
      let response;
      if (language === "en") {
        response = await axios.get(MAPIURLMaker("games/checkENWord"), {
          params: {
            word: input,
            lang: "EN",
          },
        });
      } else {
        response = await axios.get(MAPIURLMaker("games/check-input"), {
          params: { word: input },
        });
      }

      if (response.data && !foundWords.includes(input)) {
        const wordScore = calculateScore(input);
        setFoundWords([...foundWords, input]);
        setScore(score + wordScore);
        setPopupMessage(i18n.t("wordWander.correct", { input, wordScore }));
        setPopupType("positive");
        setTimeout(() => {
          setPopupMessage("");
          setPopupType("neutral");
        }, 1000);
      } else {
        setPopupMessage(i18n.t("wordWander.invalid"));
        setTimeout(() => setPopupMessage(""), 1000);
      }
    } catch (error) {
      setPopupMessage(i18n.t("wordWander.error"));
      console.error("Error checking word:", error);
    }

    setInput("");
  };

  const handleKeyboardInput = (inputKey) => {
    if (inputKey === "{bksp}") {
      setInput((prev) => prev.slice(0, -1));
    } else if (inputKey === "{enter}") {
      const normalizedInput = input.toLowerCase();
      handleSubmitWord(normalizedInput);
    } else {
      const correctedInput = handleSpecialCharacters(inputKey);
      setInput((prev) => prev + correctedInput);
    }
  };

  // Conversion for Turkish letters
  const handleSpecialCharacters = (inputKey) => {
    switch (inputKey) {
      case "I":
        return "ı";
      case "İ":
        return "i";
      case "Ç":
        return "ç";
      case "Ğ":
        return "ğ";
      case "Ö":
        return "ö";
      case "Ü":
        return "ü";
      default:
        return inputKey.toLowerCase();
    }
  };

  // Focus on the input field once the game starts
  useEffect(() => {
    if (gameStarted) {
      inputRef.current?.focus();
    }
  }, [gameStarted]); // Dependency on gameStarted

  useEffect(() => {
    if (!gameOver && inputRef.current) {
      inputRef.current?.focus(); // Refocus the input if not game over
    }
  }, [input, gameOver]); // Trigger refocus when input changes

  const handleStartGame = () => {
    setGameStarted(true);
    inputRef.current?.focus();
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    // Backspace check
    if (e.key === "Backspace") {
      setInput((prev) => prev.slice(0, -1));
    }
    // Enter check
    else if (e.key === "Enter") {
      const normalizedInput = input.toLowerCase();
      handleSubmitWord(normalizedInput);
    }
    // Accept letters only
    else if (/^[a-zA-ZğĞüÜşŞıİçÇöÖ]$/.test(e.key)) {
      let correctedKey = e.key;
      switch (correctedKey) {
        case "I":
          correctedKey = "ı";
          break;
        case "İ":
          correctedKey = "i";
          break;
        default:
          correctedKey = correctedKey.toLowerCase();
      }
      setInput((prev) => prev + correctedKey);
    }
  };

  const handleInfoButtonClick = () => {
    setShowGameRules(true); // Show the game rules popup
  };

  const handleClosePopup = () => {
    setShowGameRules(false); // Close the game rules popup
  };

  return (
    <div style={{ textAlign: "center" }}>
      {/* React Helmet for Meta Tags */}
      <Helmet>
        <html lang={language} />
        <title>
          {language === "tr"
            ? "Kelime Bulmaca Oyunu - Sözlük ile Oyna ve Öğren"
            : "Word Wander Game - Play and Learn with the Dictionary"}
        </title>
        <meta
          name="description"
          content={
            language === "tr"
              ? "Kelime bilginizi geliştirmek için bu eğlenceli kelime oyununu oynayın. Sözlük uygulamamızla yeni kelimeler öğrenin ve eğlenin!"
              : "Play this fun word game to improve your vocabulary. Learn new words and enjoy with our dictionary app!"
          }
        />
        <meta
          name="keywords"
          content={
            language === "tr"
              ? "kelime oyunu, sözlük oyunu, kelime öğrenme, kelime bulma"
              : "word game, dictionary game, learn words, find words"
          }
        />
        <meta property="og:type" content="game" />
        <meta
          property="og:title"
          content={
            language === "tr"
              ? "Kelime Oyunu - Sözlük ile Oyna ve Öğren"
              : "Word Game - Play and Learn with the Dictionary"
          }
        />
        <meta
          property="og:description"
          content={
            language === "tr"
              ? "Kelime bilginizi test edin ve sözlük uygulamamızla yeni kelimeler öğrenin."
              : "Test your word knowledge and learn new words with our dictionary app."
          }
        />
        <meta property="og:type" content="game" />
        <meta property="og:url" content="https://lamba.dev/games/wordwander" />
        <meta
          property="og:image"
          content="https://lamba.dev/wordwander-preview.png"
        />
      </Helmet>
      {!gameStarted && (
        <GameRules
          rulesText={gameRulesText}
          onStartGame={handleStartGame}
          onLevelChange={handleLevelChange}
          onLanguageChange={handleLanguageChange}
        />
      )}

      {gameStarted && (
        <>
          {showConfetti && (
            <Confetti width={window.innerWidth} height={window.innerHeight} />
          )}
          <div style={{ margin: 0 }}>
            <button
              style={{
                background: "transparent",
                color: "#e78700",
                border: "none",
                padding: "0px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px",
                marginLeft: "10px",
              }}
              onClick={handleInfoButtonClick}
            >
              <h2 style={{ fontSize: "15px", marginRight: "5px" }}>
                {i18n.t("wordWander.gameRules.howToPlay")}
              </h2>
              <HelpOutlineIcon
                style={{
                  borderRadius: "50%",
                  background: "#f7d759",
                  color: "#e78700",
                  fontSize: "20px",
                }}
              />
            </button>
          </div>
          <div
            className="info-field"
            style={{ display: "inline-block", alignItems: "center" }}
          >
            <h1 className="game-name">{i18n.t("wordWander.name")}</h1>
            <div>
              <UILangSwitcher
                interfaceLanguage={interfaceLanguage}
                onInterfaceLanguageChange={handleInterfaceLanguageChange}
              />
            </div>
          </div>

          {popupMessage && (
            <GameMessage message={popupMessage} type={popupType || "neutral"} />
          )}

          {/* Game End Screen */}
          {endMessage && (
            <GameEndScreen
              message={endMessage}
              onRetry={startGame}
              buttonText={i18n.t("wordWander.playAgain")}
            />
          )}

          <div className="randoms-container">
            <h3 className="randoms">
              {i18n.t("wordWander.letters")} {letters.join(", ")}
            </h3>
          </div>
          <div
            className="flex-row"
            style={{ justifyContent: "center", width: "100%" }}
          >
            <div style={{ position: "relative" }}>
              <input
                ref={inputRef}
                className="input-field"
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={i18n.t("wordWander.placeholder")}
                style={{
                  paddingRight: "30px", // Space for the X button
                }}
              />
              {input && ( // Show the X button only if there's input
                <button
                  className="input-delete"
                  onClick={() => setInput("")} // Clear the input
                  style={{
                    right: "10px", // Position the X button to the right
                  }}
                >
                  <div className="input-delete-icon">
                    <img src={deleteIcon} alt="Delete" />
                  </div>
                </button>
              )}
            </div>
            <button
              className="game-button"
              onClick={handleSubmitWord}
              style={{ marginLeft: "10px" }}
            >
              {i18n.t("wordWander.submit")}
            </button>
          </div>
          <p className="scoreboard">{i18n.t("wordWander.score", { score })}</p>

          <p className="timer">{i18n.t("wordWander.timeLeft", { timeLeft })}</p>
          <KeyboardComponent handleKeyboardInput={handleKeyboardInput} />

          <div className="chalkboard">
            <h3 className="chalkboard-title">{i18n.t("wordWander.found")}</h3>
            <p className="found-words">{foundWords.join(", ")}</p>
          </div>
        </>
      )}
      {/* Popup for showing game rules */}
      {showGameRules && (
        <div className="game-rules-overlay">
          <div className="game-rules-content">
            <div className="rules-popup">
              {gameRulesText}
              <div className="button-container">
                <button
                  className="game-rules-button close-button"
                  onClick={handleClosePopup}
                >
                  {i18n.t("games.closeBtn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WordWander;
