import React, { useState, useEffect } from "react";
import axios from "axios";
import APIURLMaker from "../APIURLMaker";
import PlayArrowIcon from "@mui/icons-material/PlayArrow"; 
import '../../styles/new.css';
import NoDataMessage from "../Common/NoDataMessage";
import ErrorMessage from "../Common/ErrorMessage";
import { useTranslation } from 'react-i18next';

// State variables to manage API data, loading state, and errors
const OxfordPage = ({ lang_word }) => {
  const { t, i18n } = useTranslation();
  const [wordData, setWordData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showExamples, setShowExamples] = useState(false);
  const [showSynonyms, setShowSynonyms] = useState(false);

  // Effect hook to fetch data when lang_word changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading state to true
      setError(null); // Clear any previous errors

      try {
        const URL = APIURLMaker("dict/getEngMeaning"); // Construct URL using APIURLMaker function
        const response = await axios.post(URL, { // Fetch data from API
          lang_word: lang_word,
          lang: "EN",
        });
        setWordData(response.data); // Set fetched data to state
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error); // Set error state if fetch fails
      } finally {
        setLoading(false); // Set loading state to false after fetch completes
      }
    };

    fetchData(); // Call fetchData function on component mount and lang_word change
  }, [lang_word]); // Dependency array ensures effect runs when lang_word changes

  // Conditional rendering based on loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Conditional rendering based on error state
  if (error) {
    return (
      <div>
        {error.response && error.response.status === 404 ? (
          <NoDataMessage message={t("oxford.error.NotFound")} />
        ) : (
          <ErrorMessage message={t("oxford.error.FetchingData")} />
        )}
      </div>
    );
  }

  // Conditional rendering if no wordData or results are present
  if (!wordData || !wordData.results || wordData.results.length === 0) {
    return <NoDataMessage message={t("oxford.error.NoResults")} />;
  }

 // Render fetched data if available
 return (
  <div className="oxford-data">
    <div className="header-container">
      <h2>{lang_word}</h2>
      {wordData.results.flatMap(result =>
        result.lexicalEntries.flatMap(lexicalEntry =>
          lexicalEntry.entries.flatMap(entry =>
            entry.pronunciations?.map((pronunciation, index) => (
              <div key={`pronunciation-${index}`} className="pronunciation-container">
                <span className="pronunciation-text">
                   {pronunciation.phoneticSpelling}
                </span>
                <button
                  onClick={() => {
                    const audio = document.getElementById(`audio-${index}`);
                    if (audio.paused) {
                      audio.play();
                    } else {
                      audio.pause();
                    }
                  }}
                  className="custom-audio-button"
                >
                  <PlayArrowIcon style={{ fontSize: "20px", color: "#000" }} />
                </button>
                <audio
                  id={`audio-${index}`}
                  style={{ display: "none" }} // Hide the default audio player
                >
                  <source
                    src={pronunciation.audioFile}
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio element.
                </audio>
              </div>
            ))
          )
        )
      )}
    </div>
    <div className="scrollable-content">
      {wordData.results.map((result, resultIndex) => (
        <div key={`result-${resultIndex}`}>
          {result.lexicalEntries.map((lexicalEntry, lexIndex) => (
            <div key={`lexical-${lexIndex}`}>
              {lexicalEntry.entries.map((entry, entryIndex) => (
                <div key={`entry-${entryIndex}`}>
                  {entry.senses && (
                    <div>
                      <div className="meanings-title"> {t("supporting.modal.Title")}</div>
                      <div className="toggle-buttons-container">
                        <button
                          onClick={() => setShowExamples(!showExamples)}
                          className="toggle-button"
                        >
                          {showExamples ? t("supporting.modal.HideExamples") : t("supporting.modal.ShowExamples")}
                        </button>
                        <button
                          onClick={() => setShowSynonyms(!showSynonyms)}
                          className="toggle-button"
                        >
                          {showSynonyms ? t("supporting.modal.HideSynonyms") : t("supporting.modal.ShowSynonyms")}
                        </button>
                      </div>
                      {entry.senses.map((sense, senseIndex) => (
                        <div key={`sense-${senseIndex}`} id="sense-container" className="meaning-block">
                          <div className="sense-item">
                            <strong> {senseIndex + 1}.</strong> {sense.definitions.join("; ")}
                          </div>
                          {showExamples && sense.examples && (
                            <div className="sense-item">
                              <strong>Examples:</strong>
                              <ul>
                                {sense.examples.map((example, exampleIndex) => (
                                  <li key={`example-${exampleIndex}`}>
                                    {example.text}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {showSynonyms && sense.synonyms && (
                            <div className="synonyms">
                              <strong>Synonyms:</strong>{" "}
                              {sense.synonyms.map((synonym, synonymIndex) => (
                                <span key={`synonym-${synonymIndex}`}>
                                  {synonym.text}
                                  {synonymIndex !== sense.synonyms.length - 1 && ", "}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  {entry.etymologies && (
                    <div className="etymology-container">
                      <strong>Etymology:</strong>{" "}
                      {entry.etymologies.join(", ")}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  </div>
);
};
export default OxfordPage;