import React, { useState } from "react";
import "../../styles/gameRules.css";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import LevelSwitcher from "./LevelSwitcher";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const GameRules = ({ rulesText, onStartGame, onLevelChange, language,
  onLanguageChange, }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(true);
  const [showRules, setShowRules] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("impossible");

  const handleStartGame = () => {
    setShowOverlay(false); 
    if (onStartGame) onStartGame(); 
  };

  const handleLevelChange = (level) => {
    setSelectedLevel(level);
    if (onLevelChange) onLevelChange(level);
  };

  const handleLanguageChange = (newLang) => {
    if (onLanguageChange) {
      onLanguageChange(newLang); // Pass the language change to parent
    }
    i18n.changeLanguage(newLang); // Change language using i18n
  };

  const handleClose = () => {
    navigate("/games"); // Navigate to the /games page
  };

  return (
    <>
      {showOverlay && (
        <div className="game-rules-overlay">
          <div className="game-rules-content">
            {!showRules ? (
              <div>
              <div className="button-container">
                <button
                  className="game-rules-button"
                  onClick={() => setShowRules(true)}
                  style={{paddingRight: "10px"}}
                >
                  {i18n.t("games.howToPlay")}
                  <HelpOutlineIcon
                  style={{
                    background: "#fff",
                    borderRadius: "50%",
                    marginLeft: "0.2em",
                    color: "#e78700",
                    fontSize: "22px",
                    
                  }}
                />
                </button>
                </div>
                
                <div className="button-container">
                <button
                  className="game-rules-button start-button"
                  onClick={handleStartGame}
                >
                  {i18n.t("games.startGame")}
                </button>
                <LanguageSwitcher
              language={language}
              onLanguageChange={handleLanguageChange}
            />
                {/* <LevelSwitcher
                  selectedLevel={selectedLevel}
                  onLevelChange={handleLevelChange}
                /> */}
              </div>
              <div className="button-container">
                <button
                  className="game-rules-button close-button"
                  onClick={handleClose}
                >
                  {i18n.t("games.closeBtn")}
                </button>
                </div>
              </div>
              
            ) : (
              <div className="rules-popup">
                
                <div>{rulesText}</div>
                <div className="button-container">
                <button
                  className="game-rules-button close-button"
                  onClick={() => setShowRules(false)}
                >
                  {i18n.t("games.closeBtn")}
                </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default GameRules;