import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import APIURLMaker from "../APIURLMaker";
import ChapterCompFct from "./ChapterCompFct";
import ErrorMessage from "../Common/ErrorMessage";
import NoDataMessage from "../Common/NoDataMessage";
import { useTranslation } from "react-i18next";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

// Fetch meanings of a given word, and display the meanings organized by chapters
function ChapterBlockFct(props) {
  const { t, i18n } = useTranslation();
  const word = props.props.word; // Extract the word from props
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [hideLangPockets, setHideLangPockets] = useState(true);
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleHideLangPockets = () => {
    setHideLangPockets(!hideLangPockets);
  };

  const toggleExpandAll = () => {
    setIsExpanded(!isExpanded); // Toggle expand/collapse state for all
  };

  useEffect(() => {
    const fetchData = async () => {
      // Function to fetch data asynchronously
      setLoading(true);
      try {
        const URL = APIURLMaker(`meanings/findMeanings`); // Create the API URL
        const body = { word: `${word}` }; // Define the request body
        const response = await axios.post(URL, body); // Make a POST request using Axios
        setData(response.data); // Update state with fetched data
        setLoading(false); // Set loading state to false after fetching
      } catch (error) {
        setError(error); // Update state with error information
        setLoading(false); // Set loading state to false in case of error
      }
    };

    fetchData();

    // Cleanup function (optional)
    return () => {
      // Cancel pending requests, cleanup timers, etc.
    };
  }, [word]); // Dependency array to trigger useEffect when 'word' changes

  // Display loading message if data is being fetched
  if (loading) return <div className="meaning-block title"style={{ backgroundColor:"#f7d759", fontSize: "1rem"}}>
    {t('search.Message',{ word: `"${word}"` })}
    </div>;

  if (error) {
    // Display error message if there was an error during fetching
    console.log("error:" + error.message + error.stack);
    return (
      <ErrorMessage message={t("chapterBlock.error")} />
    );
  }

  // Display the fetched data if available
  if (data !== null && data.length > 0) {
    let meanings = data; // Store fetched meanings
    let chapters = new Map(); // Map to store chapters and their syllables
    for (let i = 0; i < meanings.length; i++) {
      let record = meanings[i];
      let item = record.chapter_id; // Get chapter id
      let syls = record.syls; // Get syllables
      chapters.set(item, syls); // Add chapter and syllables to the map
    }

    // Create a list of ChapterCompFct components for each chapter
    let chapterList = [];
    for (const [item, syls] of chapters.entries()) {
      chapterList.push(
        <ChapterCompFct
          key={item}
          name={word}
          syls={syls}
          meanings={meanings.filter((meaning) => meaning.chapter_id === item)}
          loading={loading}
          hideLangPockets={hideLangPockets}
          isExpanded={isExpanded}
        />,
        
      );
    }
    return (
      <div style={{ backgroundColor: "#E0E0E0"}}>
        <div>
        <div className="title"  style={{ display: "flex", alignItems: "center",justifyContent: "right", marginBottom: 0 }}>
          <label style={{ marginRight: "10px"}}>
            <input
              type="checkbox"
              checked={hideLangPockets}
              onChange={toggleHideLangPockets} 
            />
            {t("main.HideLangPockets")}
          </label>
          <div onClick={toggleExpandAll} style={{ cursor: "pointer" }}>
              {isExpanded ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
              
            </div>
        </div>
        {chapterList}
        </div>
      </div>
    );
  } else {
    return (
      <div className="mid-font">
        <NoDataMessage message={t("chapterBlock.noData")} />
      </div>
    ); // Display message if no data found
  }
}

export default ChapterBlockFct;