function APIURLMaker(target) {
  const PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
  const SERVER = process.env.REACT_APP_API_SERVER;
  const PORT = process.env.REACT_APP_API_PORT;

  // Constructs and returns an API URL based on environment variables for protocol, server, port, and a given target endpoint

  var retval = `${PROTOCOL}://${SERVER}:${PORT}/api/${target}`; // Construct the URL with protocol, server, port, and target

  if (PORT === "") {
    retval = `${PROTOCOL}://${SERVER}/api/${target}`; // If port is empty, construct the URL without the port
    // console.log("Removing port as it is empty");
  } else {
    //console.log(`Using port : ${PORT}`);
  }
  //console.log("API URL Maker -> " + retval);
  return retval;
}

export default APIURLMaker;
