import React from "react";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

function HistoryIconsFct(props) {
  const { count, scale } = props; // Destructure count and scale from props
  let stars = Math.round(count * scale); // Calculate the number of stars based on count and scale

  const items = []; // Array to store the icons to be rendered
  let color = "red"; // Default color for the icons

  // Determine color based on the number of stars
  switch (stars) {
    case 0:
      color = "green";
      break;
    case 1:
      color = "#b8e602";
      break;
    case 2:
      color = "#f7d759";
      break;
    case 3:
      color = "orange";
      break;
    case 4:
      color = "#f50b02";
      break;
    case 5:
      color = "#9c0000";
      break;
    default:
      break;
  }

  // If no stars, push an ArrowRightAlt icon into items array
  if (stars === 0) {
    items.push(
      <TrendingUpIcon style={{ color: color }} key={0} fontSize="small" />,
    );
  } else {

    // For each star, push a Bright or Medal icon into items array
    for (let i = 0; i < stars; i++) {
      if (stars === 5) {
        items.push(<EmojiEventsIcon style={{ color: color }} key={i} fontSize="small" />);
      } else {
        items.push(
          <WhatshotIcon style={{ color: color }} key={i} fontSize="small" />,
        );
      }
    }
  }

  return <div>{items}</div>; // Render the icons
}

export default HistoryIconsFct; // Export the HistoryIconsFct component as the default export