import React from 'react';

// ChartImage Component
function StaticContent({ src, alt }) {
  return (
    <div style={{ textAlign: 'center' }}>
      <img src={src} alt={alt} style={{ maxWidth: '100%', height: 'auto' }} />
    </div>
  );
}

export default StaticContent;