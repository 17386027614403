import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/gamesContainer.css";
import "../../styles/new.css";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import UILangSwitcher from "./UILangSwitcher";

function GamesContainer() {
  const { t, i18n } = useTranslation();
  const [interfaceLanguage, setInterfaceLanguage] = useState("tr");

  const handleInterfaceLanguageChange = (newLang) => {
    setInterfaceLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  return (
    <div className="games-container">
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <UILangSwitcher
          interfaceLanguage={interfaceLanguage}
          onInterfaceLanguageChange={handleInterfaceLanguageChange}
        />
      </div>
      <div className="games-grid">
        <div className="game-box game-button">
          <Link to="/games/wordle" className="game-link">
            {i18n.t("games.wordle")}
          </Link>
        </div>
        <div className="game-box game-button">
          <Link to="/games/hangman" className="game-link">
            {i18n.t("games.hangman")}
          </Link>
        </div>
        <div className="game-box game-button">
          <Link to="/games/wordscramble" className="game-link">
            {i18n.t("games.wordScramble")}
          </Link>
        </div>
        <div className="game-box game-button">
          <Link to="/games/wordwander" className="game-link">
            {i18n.t("games.wordWander")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GamesContainer;
