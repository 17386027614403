import {
  BrowserView, //A component that renders its children only on browser devices
  MobileView, //A component that renders its children only on mobile devices
  isBrowser, //Booleans indicating whether the current device is a browser or a mobile device
  isMobile,
} from "react-device-detect"; //Library to detect the device type to render different components based on it
import React from "react";

import { Grid, Paper } from "@mui/material"; //Grid arranges the page in a grid; Paper provides a paper-like background

//Render Google-Ads section with a grid structure and a paper-like background
function GoogleAdsColumn(word) {
  var content = (
    <Paper className="main-container paper">
      <div className="title flex-row comp-header">
        <div className="fct-header">Google Ads</div>
      </div>
    </Paper>
  );

  return content;
}

export default GoogleAdsColumn;