import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import { LangContext } from "../Contexts/LangContext";
import { UserContext } from "../Contexts/UserContext";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Delete from "@mui/icons-material/DeleteForeverOutlined";
import { v4 as uuid_v4 } from "uuid";
import Icon_Oxford from "../../Images/pngegg_small.png";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import axios from "axios";
import APIURLMaker from "../APIURLMaker";
import "reactjs-popup/dist/index.css";
import Swal from "sweetalert2";
import Modal from "react-modal";
import OxfordPage from "./OxfordPage";
import ErrorMessage from "../Common/ErrorMessage";
import NoDataMessage from "../Common/NoDataMessage";
import LoadingIndicator from "../Common/LoadingIndicator";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

const FLF = (props) => {
  const { t, i18n } = useTranslation();
  const { word } = props; // Destructuring props to get 'word'
  const [langWord, setLangWord] = useState(""); // State to manage the current word being interacted with
  const { user } = useContext(UserContext); // User context
  const {
    lang1Library, // Array of language items
    updateLang1Library, // Function to update lang1Library
    updateLang1LibraryFilter, // Function to update filter for lang1Library
    unfilteredLang1Library, // Array of unfiltered language items
    updateUnfilteredLang1Library, // Function to update unfilteredLang1Library
  } = useContext(LangContext); // Language context

  const AI_CAN_EDIT_LANGS_EN = user?.roles?.includes("AI_CAN_EDIT_LANGS_EN"); // Role-based permission

  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 }); // Modal position state
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const prevWordRef = useRef();

  // Function to open modal at specified position
  const openModal = (top, left) => {
    const adjustedTop = top - 50; 
    const adjustedLeft = left - 50; 

    setModalPosition({
      top: adjustedTop < 50 ? 50 : adjustedTop,
      left: adjustedLeft < 50 ? 50 : adjustedLeft,
    });
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false); // Function to close modal

  // Function to delete an item from lang1Library
  const deleteItem = useCallback(
    (index) => {
      const newLibrary = [...lang1Library];
      const removedItem = newLibrary.splice(index, 1);
      console.log("Removed item : " + removedItem);
      updateLang1Library(newLibrary);
    },
    [lang1Library, updateLang1Library],
  );

  // Function to handle filter change for lang1Library
  const handleFilterChange = useCallback(
    (event) => {
      const text = event.target.value;
      updateLang1LibraryFilter(text);
    },
    [updateLang1LibraryFilter],
  );

  // Function to add an item to lang1Library
  const addItem = useCallback(() => {
    const newValue = document.getElementById("txtAddItem").value;

    // Check if the item already exists in lang1Library
    if (lang1Library.some((item) => item.lang_word === newValue)) {
      Swal.fire({
        title: "Error!",
        text: `This entry ("${newValue}") is already in this list! Can't add again!`,
        icon: "warning",
        confirmButtonText: "OK",
      });
    } else {
      // Calculate max order for sort_order
      const maxOrder = Math.max(
        0,
        ...unfilteredLang1Library.map((item) => item.sort_order),
      );

      // Create a new item object
      const newItem = {
        id: "@" + uuid_v4(),
        lang: "EN",
        lang_word: newValue,
        mark_as_deleted: "",
        sort_order: maxOrder + 1,
        word,
      };
      updateUnfilteredLang1Library([...unfilteredLang1Library, newItem]); // Update unfilteredLang1Library with the new item
    }
  }, [lang1Library, updateLang1Library, unfilteredLang1Library, word]);

  // Fetch data from API on component mount or when 'word' changes
  useEffect(() => {
    if (prevWordRef.current !== word) {
      const fetchData = async () => {
        setLoading(true);
        setError(null);
        updateUnfilteredLang1Library([]);
        updateLang1Library([]);

        try {
          //console.log("Sending a DB call over axios ..");
          const URL = APIURLMaker("langs/findLangWords");
          const response = await axios.post(URL, { word, lang: "EN" });

          if (response.data) {
            setLoading(false);
          }

          if (response.data.length === 0) {
            // If no results, clear lang1Library and unfilteredLang1Library
            updateUnfilteredLang1Library([]);
            updateLang1Library([]);
          } else {
            // Update unfilteredLang1Library and lang1Library with fetched data
            updateUnfilteredLang1Library(response.data);
            updateLang1Library(response.data); // Added to initialize lang1Library
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setError(
            t("flf.error.fetch")
          );
        } finally {
          setLoading(false);
        }
      };

      fetchData();
      prevWordRef.current = word;
    }
  }, [word, updateLang1Library, updateUnfilteredLang1Library]); // Updated dependencies

  // If there's an error, render the ErrorMessage component
  if (error) {
    return (
      <ErrorMessage message={t("flf.error.fetch")} />
    );
  }

  // If loading, show loading indicator
  if (loading) {
    return <LoadingIndicator loading={loading} />;
  }

  // If lang1Library is empty after fetching and not loading, show "No synonyms" error
  {
    /*if (!loading && lang1Library.length === 0 && word) {
    return (
      <ErrorMessage message="No synonyms found for the entered word. Please check your spelling or try a different word!" />
    );
  }*/
  }

  // Function to render modal content
  const renderModalContent = () => {
    return (
      <Modal
        isOpen={showModal} // Control modal visibility based on state
        onRequestClose={closeModal} // Function to close the modal
        contentLabel="Example Modal" // Accessible label for modal content
        className="Modal" // CSS class for modal styling
        overlayClassName="Overlay" // CSS class for modal overlay styling
        portalClassName="ModalPortal" // CSS class for modal portal styling
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
          content: {
            top: modalPosition.top,
            left: modalPosition.left,
            transform: "translate(-50%, -50%)",
            maxWidth: "80%",
            maxHeight: "80%",
            overflowY: "auto",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            zIndex: 1000,
          },
        }}
      >
        <div className="tabs flex-row" style={{ justifyContent: "left" }}>
          <IconButton
            onClick={closeModal}
            className="CloseButton"
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div>
          <OxfordPage lang_word={langWord} />
        </div>

        {/* Add the close button at the end of the modal */}
        <div className="flex-row" style={{ justifyContent: "center" }}>
          <IconButton
            onClick={closeModal}
            className="CloseButton"
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      {/* JSX code for input, button, and droppable container */}
      {!loading && lang1Library.length === 0 ? (
        <div className="mid-font">
          {!word && !loading ? (
            t("flf.nodata.word")
          ) : error ? (
            <ErrorMessage message={t("flf.error.fetch")} />
          ) : (
            <NoDataMessage message={t("flf.error.nosynonyms")} />
          )}
        </div>
      ) : (
        <Droppable droppableId="English" direction="vertical">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="col-container-lang1 padding-mid tab-content"
              style={{
                backgroundColor: snapshot.isDraggingOver
                  ? "#eedbe5"
                  : "#f8f6f7",
              }}
            >
              {lang1Library.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`lang#${item.id}`}
                  index={index}
                  isDragDisabled={!AI_CAN_EDIT_LANGS_EN}
                >
                  {(provided, snapshot) => (
                    <div
                      className="flex-row ppp"
                      style={{ alignItems: "center" }}
                    >
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="word"
                        style={{
                          backgroundColor: snapshot.isDragging
                            ? "#8b607b"
                            : "#E0E0E0",
                          ...provided.draggableProps.style,
                          flexGrow: 1,
                        }}
                      >
                        <Tooltip title={t("supporting.OxfordHover")} arrow>
                          <div style={{ marginLeft: "4px" }}>
                            <img
                              src={Icon_Oxford}
                              alt="Oxford Dictionaries"
                              width="22"
                              height="22"
                              onClick={() =>
                                console.log("Clicked on Oxford item")
                              }
                            />
                          </div>
                        </Tooltip>

                        <div style={{ flexGrow: 0.1 }} />

                        {item.lang_word}
                        <div style={{ flexGrow: 0.1 }} />
                        <div
                          style={{ width: "10px", padding: "5px 5px 0px 0px" }}
                        >
                          <Tooltip title={t("supporting.InfoHover")} arrow>
                            <InfoIcon
                              className="info-icon"
                              onClick={(event) => {
                                event.stopPropagation();
                                const rect =
                                  event.target.getBoundingClientRect();
                                const scrollTop =
                                  window.pageYOffset ||
                                  document.documentElement.scrollTop;
                                const scrollLeft =
                                  window.pageXOffset ||
                                  document.documentElement.scrollLeft;
                                setLangWord(item.lang_word);
                                openModal(
                                  rect.top + scrollTop + rect.height / 2,
                                  rect.left + scrollLeft + rect.width / 2,
                                );
                              }}
                            />
                          </Tooltip>
                        </div>

                        <div style={{ flexGrow: 2 }} />
                        {AI_CAN_EDIT_LANGS_EN && (
                          <Delete
                            className="deleteIcon"
                            onClick={() => deleteItem(index)}
                            aria-label="Delete Icon"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}

      {/* Modal for example content */}
      {renderModalContent()}
    </div>
  );
};

export default FLF;