import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import LangContextProvider from "./Components/Contexts/LangContext";
import MainContainerFct from "./Components/Meanings/MainContainerFct";
import ModalContextProvider from "./Components/Contexts/ModalContext";
import UserContextProvider from "./Components/Contexts/UserContext";
import WordContextProvider from "./Components/Contexts/WordContext";
import { useEffect } from "react";
import MenuBar from "./Components/MenuBar";
import { TutorialProvider } from "./Components/Common/TutorialContext";
import { useTranslation } from "react-i18next";
import GamesContainer from "./Components/Games/GamesContainer";
import Wordle from "./Components/Games/Wordle";
import Hangman from "./Components/Games/Hangman";
import WordScrambleGame from "./Components/Games/WordScrambleGame";
import WordWander from "./Components/Games/WordWander";

const helmetContext = {};

function AppFct() {
  const [currentPage, setCurrentPage] = useState("home");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <HelmetProvider context={helmetContext}>
      {" "}
      {/* Wrap with HelmetProvider */}
      <UserContextProvider>
        <WordContextProvider>
          <ModalContextProvider>
            <LangContextProvider>
              <TutorialProvider>
              <Router>
      <MenuBar />
      <Routes>
      <Route path="/" element={<MainContainerFct />} />
        <Route path="/games" element={<GamesContainer />} />
        <Route path="/games/wordle" element={<Wordle />} />
        <Route path="/games/hangman" element={<Hangman />} />
        <Route path="/games/wordscramble" element={<WordScrambleGame />} />
        <Route path="/games/wordwander" element={<WordWander />} />
      </Routes>
    </Router>
              </TutorialProvider>
            </LangContextProvider>
          </ModalContextProvider>
        </WordContextProvider>
      </UserContextProvider>
    </HelmetProvider>
  );
}

export default AppFct;