import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "../../styles/new.css";
import Confetti from "react-confetti";
import axios from "axios";
import MAPIURLMaker from "../MAPIURLMaker";
import KeyboardComponent from "./KeyboardComponent";
import GameRules from "./GameRules";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import BackspaceIcon from "@mui/icons-material/Backspace";
import GameMessage from "./GameMessage";
import GameEndScreen from "./GameEndScreen";
import deleteIcon from "../../Images/delete_message.png";
import UILangSwitcher from "./UILangSwitcher";

const Hangman = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("tr");
  const [interfaceLanguage, setInterfaceLanguage] = useState("tr");
  const [level, setLevel] = useState("impossible");
  const [word, setWord] = useState([]);
  const [guessWord, setGuessWord] = useState([]);
  const [wrongGuesses, setWrongGuesses] = useState([]);
  const [mistakes, setMistakes] = useState(0);
  const [popupMessage, setPopupMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [showGameRules, setShowGameRules] = useState(false);
  const [endMessage, setEndMessage] = useState(null);
  const inputRef = useRef(null);
  const maxMistakes = 6;

  // Games rules for Hangman
  const gameRulesText = (
    <div>
      <h2>{i18n.t("hangman.gameRules.welcome")}</h2>
      <p>{i18n.t("hangman.gameRules.goal")}</p>
      <h3>{i18n.t("hangman.gameRules.howToPlay")}</h3>
      <ul>
        <li>{i18n.t("hangman.gameRules.rule1")}</li>
        <li>{i18n.t("hangman.gameRules.rule2")}</li>
        <li>{i18n.t("hangman.gameRules.rule3")}</li>
        <li>{i18n.t("hangman.gameRules.rule4")}</li>
      </ul>
      <p>{i18n.t("hangman.gameRules.goodLuck")}</p>
    </div>
  );

  const getRandomWord = async () => {
    try {
      let url = "";

      const randomLength = Math.floor(Math.random() * (9 - 3 + 1)) + 3;

      if (language === "en") {
        setLanguage("en");
        url =
          MAPIURLMaker("games/getRandomGameWord") +
          `?lang=EN&length=${randomLength}`;
          console.log(url);
      } else {
        url = MAPIURLMaker("games/single-random-word", { language, level });
      }

      const response = await axios.get(url);
      const randomWord = response.data;

      /* // If no valid word is found, log error or handle appropriately
      if (randomWord.length > 9) {
        console.error("No valid word found within 10 attempts");
        setPopupMessage(i18n.t("hangman.error"));
        return;
      } */

      console.log("Hangman random word:", randomWord);
      setWord(randomWord.split(""));
      setGuessWord(new Array(randomWord.length).fill("_"));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching words: ", error);
      setPopupMessage(i18n.t("hangman.error"));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRandomWord();
  }, [language, level]);

  useEffect(() => {
    setInterfaceLanguage(language);
  }, [language]);

  const handleLanguageChange = (newLang) => {
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const handleInterfaceLanguageChange = (newLang) => {
    setInterfaceLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const handleLevelChange = (newLevel) => {
    setLevel(newLevel);
    console.log("Selected Level:", newLevel);
    resetGame();
  };

  const resetGame = () => {
    setGameStarted(false);
    setIsLoading(true);
    setWrongGuesses([]);
    setMistakes(0);
    setPopupMessage(null);
    setEndMessage(null);
    setInputValue("");
    setShowConfetti(false);
    setIsGameOver(false);
    getRandomWord();
    inputRef.current?.focus();
  };

  const handleGuess = (input) => {
    if (isGameOver) {
      if (inputRef.current) {
        inputRef.current.blur();
      }
      return;
    }

    const lowerInput = input.toLowerCase(); // Convert input to lowercase
    const lowerWord = word.map((char) => char.toLowerCase());

    if (wrongGuesses.includes(lowerInput) || guessWord.includes(lowerInput)) {
      setPopupMessage(i18n.t("hangman.sameInput"));
      setTimeout(() => setPopupMessage(null), 1000);
      return;
    }

    // If the input is a letter
    if (lowerInput.length === 1) {
      if (lowerWord.includes(lowerInput)) {
        // If the letter is in the word
        const updatedGuessWord = [...guessWord];
        word.forEach((char, index) => {
          if (char.toLowerCase() === lowerInput) {
            updatedGuessWord[index] = char;
          }
        });
        setGuessWord(updatedGuessWord);

        // If the word is fully guessed
        if (updatedGuessWord.join("") === word.join("")) {
          setShowConfetti(true);
          setEndMessage(i18n.t("hangman.winMessage"));
          setIsGameOver(true);
          return;
        }
      } else {
        // If the letter is not in the word
        if (!wrongGuesses.includes(lowerInput)) {
          setWrongGuesses((prev) => [...prev, lowerInput]);
          const newMistakes = mistakes + 1;
          setMistakes(newMistakes); // Increment the mistake count

          // Check if the new mistake count exceeds the maximum
          if (newMistakes >= maxMistakes) {
            setEndMessage(`${i18n.t("hangman.lose")} ${word.join("")}`);
            setIsGameOver(true);
            return;
          }
        }
      }
    }
    // If the input is a word
    else if (lowerInput.length > 1) {
      if (lowerInput === lowerWord.join("")) {
        setShowConfetti(true);
        setEndMessage(i18n.t("hangman.winMessage"));
        setIsGameOver(true);
        return;
      } else {
        setWrongGuesses((prev) => [...prev, lowerInput]);
        const newMistakes = mistakes + 1;
        setMistakes(newMistakes); // Increment the mistake count

        // Check if the new mistake count exceeds the maximum
        if (newMistakes >= maxMistakes) {
          setEndMessage(`${i18n.t("hangman.lose")} ${word.join("")}`);
          setIsGameOver(true);
          return;
        }
      }
    }
  };

  // Turkish letter conversion function
  const handleSpecialCharacters = (inputKey) => {
    switch (inputKey) {
      case "I":
        return "ı";
      case "İ":
        return "i";
      case "Ç":
        return "ç";
      case "Ğ":
        return "ğ";
      case "Ö":
        return "ö";
      case "Ü":
        return "ü";
      case "Ş":
        return "ş";
      default:
        return inputKey.toLowerCase();
    }
  };

  const handleKeyboardInput = (key) => {
    if (key === "{bksp}") {
      setInputValue((prev) => prev.slice(0, -1));
    } else if (key === "{enter}") {
      if (inputValue.trim() !== "") {
        handleGuess(inputValue.trim());
        setInputValue("");
      }
    } else {
      const transformedKey = handleSpecialCharacters(key);
      setInputValue((prev) => prev + transformedKey);
    }
  };

  const handleRestart = () => {
    setIsLoading(true);
    setWrongGuesses([]);
    setMistakes(0);
    setPopupMessage(null);
    setEndMessage(null);
    setInputValue("");
    setShowConfetti(false);
    setIsGameOver(false);
    getRandomWord();
    inputRef.current?.focus();
  };

  // Focus on the input field once the game starts
  useEffect(() => {
    if (gameStarted) {
      inputRef.current?.focus();
    }
  }, [gameStarted]); // Dependency on gameStarted

  const handleStartGame = () => {
    setGameStarted(true);
    inputRef.current?.focus();
  };

  const isWinner = guessWord.join("") === word.join("");
  const isLoser = mistakes >= maxMistakes;

  const handleInfoButtonClick = () => {
    setShowGameRules(true); // Show the game rules popup
  };

  const handleClosePopup = () => {
    setShowGameRules(false); // Close the game rules popup
  };

  const handleKeyDown = (e) => {
    e.preventDefault();

    if (e.key === "Backspace") {
      setInputValue((prev) => prev.slice(0, -1));
    } else if (e.key === "Enter") {
      const normalizedInput = inputValue.toLowerCase();
      handleGuess(normalizedInput);
      setInputValue("");
    } else if (/^[a-zA-ZğĞüÜşŞıİçÇöÖ]$/.test(e.key)) {
      let correctedKey = e.key;

      switch (correctedKey) {
        case "I":
          correctedKey = "ı";
          break;
        case "İ":
          correctedKey = "i";
          break;
        default:
          correctedKey = correctedKey.toLowerCase();
      }

      // Input değerine ekle
      setInputValue((prev) => prev + correctedKey);
    }
  };

  return (
    <div>
      {/* React Helmet for Meta Tags */}
      <Helmet>
        <html lang={language} />
        <title>
          {language === "tr"
            ? "Adam Asmaca - Sözlük ile Oyna ve Öğren"
            : "Hangman Game - Play and Learn with the Dictionary"}
        </title>
        <meta
          name="description"
          content={
            language === "tr"
              ? "Kelime bilginizi geliştirmek için bu eğlenceli kelime oyununu oynayın. Sözlük uygulamamızla yeni kelimeler öğrenin ve eğlenin!"
              : "Play this fun word game to improve your vocabulary. Learn new words and enjoy with our dictionary app!"
          }
        />
        <meta
          name="keywords"
          content={
            language === "tr"
              ? "kelime oyunu, sözlük oyunu, kelime öğrenme, kelime bulma"
              : "word game, dictionary game, learn words, find words"
          }
        />
        <meta property="og:type" content="game" />
        <meta
          property="og:title"
          content={
            language === "tr"
              ? "Kelime Oyunu - Sözlük ile Oyna ve Öğren"
              : "Word Game - Play and Learn with the Dictionary"
          }
        />
        <meta
          property="og:description"
          content={
            language === "tr"
              ? "Kelime bilginizi test edin ve sözlük uygulamamızla yeni kelimeler öğrenin."
              : "Test your word knowledge and learn new words with our dictionary app."
          }
        />
        <meta property="og:type" content="game" />
        <meta property="og:url" content="https://lamba.dev/games/hangman" />
        <meta
          property="og:image"
          content="https://lamba.dev/hangman-preview.png"
        />
      </Helmet>
      <div style={{ textAlign: "center" }}>
        {!gameStarted && (
          <GameRules
            rulesText={gameRulesText}
            onStartGame={handleStartGame}
            onLevelChange={handleLevelChange}
            onLanguageChange={handleLanguageChange}
          />
        )}

        {gameStarted && (
          <>
            {showConfetti && (
              <Confetti width={window.innerWidth} height={window.innerHeight} />
            )}
            <div style={{ margin: 0 }}>
              <button
                style={{
                  background: "transparent",
                  color: "#e78700",
                  border: "none",
                  padding: "0px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "30px",
                  marginLeft: "10px",
                }}
                onClick={handleInfoButtonClick}
              >
                <h2 style={{ fontSize: "15px", marginRight: "5px" }}>
                  {i18n.t("hangman.gameRules.howToPlay")}
                </h2>
                <HelpOutlineIcon
                  style={{
                    borderRadius: "50%",
                    background: "#f7d759",
                    color: "#e78700",
                    fontSize: "20px",
                  }}
                />
              </button>
            </div>

            <div className="info-field" style={{ alignItems: "center" }}>
              <h1 className="game-name">{i18n.t("hangman.name")}</h1>
            </div>

            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <UILangSwitcher
                interfaceLanguage={interfaceLanguage}
                onInterfaceLanguageChange={handleInterfaceLanguageChange}
              />
            </div>

            {popupMessage && <GameMessage message={popupMessage} />}
            {endMessage && (
              <GameEndScreen
                message={endMessage}
                onRetry={handleRestart}
                buttonText={i18n.t("hangman.newGame")}
              />
            )}
            <div className="randoms-container">
              <p
                className="randoms"
                id="guess-field"
                style={{ fontSize: "2em", padding: "0.5em 1.2em" }}
              >
                {guessWord.join(" ")}
              </p>
            </div>

            <div
              className="flex-row"
              style={{ justifyContent: "center", width: "100%" }}
            >
              <div style={{ position: "relative" }}>
                <input
                  ref={inputRef}
                  className="input-field"
                  type="text"
                  placeholder={i18n.t("hangman.placeholder")}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  style={{
                    paddingRight: "30px", // Space for the X button
                  }}
                />
                {inputValue && ( // Show the X button only if there's input
                  <button
                    className="input-delete"
                    onClick={() => setInputValue("")} // Clear the input
                    style={{
                      right: "10px", // Position the X button to the right
                    }}
                  >
                    <div className="input-delete-icon">
                      <img src={deleteIcon} alt="Delete" />
                    </div>
                  </button>
                )}
              </div>
              <button
                className="game-button"
                onClick={() => {
                  handleGuess(inputValue);
                  setInputValue("");
                }}
                disabled={isGameOver} // Disable button when game is over
              >
                {i18n.t("hangman.guess")}
              </button>
            </div>

            <p id="wrong-letters">
              <strong>{i18n.t("hangman.wrongGuesses")}</strong>{" "}
              {wrongGuesses.join(", ")}
            </p>
            <p id="mistakes-counter">
              <strong>{i18n.t("hangman.mistakes")}</strong> {mistakes}/
              {maxMistakes}
            </p>

            <div id="mistakes-bar" style={{ margin: "20px 0" }}>
              <div
                style={{
                  height: "20px",
                  width: `${(mistakes / maxMistakes) * 100}%`,
                  backgroundColor: mistakes === maxMistakes ? "red" : "orange",
                  transition: "width 0.3s ease",
                }}
              />

              <br />
              <button className="game-button" onClick={handleRestart}>
                {i18n.t("hangman.newGame")}
              </button>
            </div>
            <KeyboardComponent handleKeyboardInput={handleKeyboardInput} />
          </>
        )}
        {/* Popup for showing game rules */}
        {showGameRules && (
          <div className="game-rules-overlay">
            <div className="game-rules-content">
              <div className="rules-popup">
                {gameRulesText}
                <div className="button-container">
                  <button
                    className="game-rules-button close-button"
                    onClick={handleClosePopup}
                  >
                    {i18n.t("games.closeBtn")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hangman;
