import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import ExtensionIcon from "@mui/icons-material/Extension";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HomeIcon from "@mui/icons-material/Home";
import AppIcon from "../Images/favicon.png";
//import AppIcon from "../Images/lamba-cover3.png";
import { useTranslation } from "react-i18next";
import "../styles/new.css";

const MenuBar = () => {
  const { t, i18n } = useTranslation();

  const [selectedPage, setSelectedPage] = useState(t("games"));
  const [showWordLists, setShowWordLists] = useState(false);

  const pageMap = {
    [t("games")]: "/games",
    [t("games.wordle")]: "/games/wordle",
    [t("games.hangman")]: "/games/hangman",
    [t("games.wordScramble")]: "/games/wordscramble",
    [t("games.wordWander")]: "/games/wordwander",
    [t("menu1.Top100")]: "/turkce-en-cok-kullanilan-100-kelime-lamba.pdf",
    [t("menu1.Top200")]: "/turkce-en-cok-kullanilan-200-kelime-lamba.pdf",
    [t("menu1.Top300")]: "/turkce-en-cok-kullanilan-300-kelime-lamba.pdf",
    [t("menu1.Top400")]: "/turkce-en-cok-kullanilan-400-kelime-lamba.pdf",
    [t("menu1.Top500")]: "/turkce-en-cok-kullanilan-500-kelime-lamba.pdf",
  };

  useEffect(() => {
    // Update the selectedPage when the language changes
    setSelectedPage(t("games"));
  }, [i18n.language, t]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedPage(selectedValue);

    const targetPage = pageMap[selectedValue];
    if (targetPage) {
      if (targetPage.startsWith("http") || targetPage.endsWith(".pdf")) {
        window.open(targetPage, "_blank");
      } else {
        window.location.href = targetPage;
      }
    } else {
      console.warn(
        "Selected value does not have a corresponding page:",
        selectedValue,
      );
    }
  };

  const toggleWordLists = () => {
    setShowWordLists((prev) => !prev); // Toggle visibility
  };

  const handleGamesClick = () => {
    setSelectedPage(t("games"));
    window.location.href = "/games"; // Navigates to /games directly
  };

  const handleAppNameClick = () => {
    window.location.href = "/";
  };

  return (
    <AppBar
      position="static"
      style={{
        background: "#F8F6F7",
        color: "#467696",
        height: "3rem",
        border: "2px solid",
        borderColor: "#467696",
        borderRadius: "10px",
      }}
    >
      <Toolbar variant="dense">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          {/* Left: App Name and Icon */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={handleAppNameClick}
            style={{ height: "100%" }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                fontFamily: "'Arial', sans-serif",

                cursor: "pointer",
                display: "inline-block",
                lineHeight: "3rem",
              }}
            >
              {t("app.name")}
            </Typography>
            <img
              src={AppIcon}
              alt="AppIcon"
              width="30em"
              height="30em"
              style={{
                marginLeft: "0.3em",
                marginRight: "0.2em",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
            />
            <Typography
              variant="h6"
              component="div"
              style={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                fontFamily: "'Arial', sans-serif",
                marginLeft: "0.2em",
                cursor: "pointer",
                display: "inline-block",
                lineHeight: "3rem",
              }}
            >
              {t("app.nameExtension")}
            </Typography>
          </Box>

          {/* Right: ComboBox */}
          <Box
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              style={{
                minWidth: 115,
                height: "2.5rem", // Match the desired height
                margin: 0, // Remove extra margins
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Select
                value={selectedPage}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Select Page" }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#E0E0E0",
                      color: "#467696",
                      fontSize: "0.8rem",
                    },
                  },
                }}
                sx={{
                  height: "2.3rem",
                  fontSize: "0.8rem", // Adjust text size
                  fontWeight: "bold",
                  color: "#467696",
                  backgroundColor: "#E0E0E0",
                  border: "1px solid #F7D759",
                  borderRadius: "10px",
                  "& .MuiSelect-icon": {
                    top: "calc(40% - 0.5rem)", // Center the dropdown icon
                    color: "#467696",
                  },
                }}
              >
                {/* Default Menu Title */}
                <MenuItem
                  value={t("games")}
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    backgroundColor: "#F7D759",
                    color: "#467696",
                    "&:hover": {
                      backgroundColor: "#F7D759",
                    },
                  }}
                  onClick={handleGamesClick} // Special onClick for the "Games" option
                >
                  <Box display="flex" alignItems="center">
                    <ExtensionIcon
                      sx={{
                        marginRight: "0.5rem",
                        fontSize: "1rem",
                        color: "#467696",
                      }}
                    />
                    {t("games")}
                  </Box>
                </MenuItem>

                {/* Games */}
                <MenuItem
                  value={t("games.wordle")}
                  sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                >
                  {t("games.wordle")}
                </MenuItem>
                <MenuItem
                  value={t("games.hangman")}
                  sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                >
                  {t("games.hangman")}
                </MenuItem>
                <MenuItem
                  value={t("games.wordScramble")}
                  sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                >
                  {t("games.wordScramble")}
                </MenuItem>
                <MenuItem
                  value={t("games.wordWander")}
                  sx={{ fontSize: "0.8rem", fontWeight: "bold" }}
                >
                  {t("games.wordWander")}
                </MenuItem>

                <Divider
                  sx={{ backgroundColor: "#467696", marginY: "0.5rem" }}
                />

                {/* Word Lists */}

                <MenuItem
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: "bold",
                    cursor: "default",
                    display: "flex",
                    justifyContent: "flex-start",
                    backgroundColor: "#1976D214",
                    color: "#467696",
                  }}
                  aria-readonly
                >
                  <Box display="flex" alignItems="center">
                    <EmojiEventsIcon
                      sx={{
                        marginRight: "0.5rem",
                        fontSize: "1.1rem",
                        color: "#467696",
                      }}
                    />
                    {t("menu1.WordLists")}
                  </Box>
                </MenuItem>
                <MenuItem
                  value={t("menu1.Top100")}
                  sx={{ fontSize: "0.8rem", fontWeight: "bold", pl: 3 }}
                >
                  {t("menu1.Top100")}
                </MenuItem>
                <MenuItem
                  value={t("menu1.Top200")}
                  sx={{ fontSize: "0.8rem", fontWeight: "bold", pl: 3 }}
                >
                  {t("menu1.Top200")}
                </MenuItem>
                <MenuItem
                  value={t("menu1.Top300")}
                  sx={{ fontSize: "0.8rem", fontWeight: "bold", pl: 3 }}
                >
                  {t("menu1.Top300")}
                </MenuItem>
                <MenuItem
                  value={t("menu1.Top400")}
                  sx={{ fontSize: "0.8rem", fontWeight: "bold", pl: 3 }}
                >
                  {t("menu1.Top400")}
                </MenuItem>
                <MenuItem
                  value={t("menu1.Top500")}
                  sx={{ fontSize: "0.8rem", fontWeight: "bold", pl: 3 }}
                >
                  {t("menu1.Top500")}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <HomeIcon
          style={{
            fontSize: "1.5rem",
            marginLeft: "0.5em",
            cursor: "pointer",
            color: "inherit",
          }}
          onClick={handleAppNameClick}
        />
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
