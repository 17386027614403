import React from "react";
import PropTypes from "prop-types";

const GameEndScreen = ({ message, onClose, onRetry, buttonText, style }) => {
  if (!message) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%)",
        backgroundColor: "black",
        color: "white",
        padding: "10px 20px",
        borderRadius: "5px",
        fontSize: "1.5em",
        zIndex: 1000,
        ...style, // Ek stiller buradan gelir
      }}
    >
      <p>{message}</p>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        {onRetry && (
          <button
            className="game-button"
            onClick={onRetry}
            
          >
            {buttonText}
          </button>
        )}
        {onClose && (
          <button
            onClick={onClose}
            style={{
              backgroundColor: "#f44336",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "10px 20px",
              fontSize: "1em",
              cursor: "pointer",
              marginTop: "10px",
            }}
          >
            Kapat
          </button>
        )}
      </div>
    </div>
  );
};

GameEndScreen.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onRetry: PropTypes.func,
  buttonText: PropTypes.string,
  style: PropTypes.object,
};

export default GameEndScreen;
