import React from "react";
import PropTypes from "prop-types";

const GameMessage = ({ message, type, onClose }) => {
  if (!message) return null;

  const backgroundColors = {
    positive: "orange",
    neutral: "black",
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: backgroundColors[type] || "black",
        color: "white",
        padding: "10px 20px",
        borderRadius: "5px",
        fontSize: "1.2em",
        zIndex: 1000,
      }}
    >
      {message}
      {onClose && (
        <button
          onClick={onClose}
          style={{
            marginLeft: "10px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            borderRadius: "3px",
            padding: "5px 10px",
            cursor: "pointer",
          }}
        >
          X
        </button>
      )}
    </div>
  );
};

GameMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["positive", "neutral"]),
  onClose: PropTypes.func, // Optional
};

export default GameMessage;
