import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Confetti from "react-confetti";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import axios from "axios";
import MAPIURLMaker from "../MAPIURLMaker";
import KeyboardComponent from "./KeyboardComponent";
import GameRules from "./GameRules";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import BackspaceIcon from "@mui/icons-material/Backspace";
import GameMessage from "./GameMessage";
import GameEndScreen from "./GameEndScreen";
import deleteIcon from "../../Images/delete_message.png";
import UILangSwitcher from "./UILangSwitcher";

const shuffleWord = (word) => {
  return word
    .split("")
    .sort(() => Math.random() - 0.5)
    .join("");
};

const WordScrambleGame = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("tr");
  const [interfaceLanguage, setInterfaceLanguage] = useState("tr");
  const [level, setLevel] = useState("impossible");
  const [originalWord, setOriginalWord] = useState("");
  const [shuffledWord, setShuffledWord] = useState("");
  const [input, setInput] = useState("");
  const [popupMessage, setPopupMessage] = useState(null);
  const [endMessage, setEndMessage] = useState(null);
  const [timeLeft, setTimeLeft] = useState(120);
  const [gameOver, setGameOver] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [showGameRules, setShowGameRules] = useState(false);
  const [foundWords, setFoundWords] = useState([]);
  const inputRef = useRef(null);

  // Games rules for Word Scramble
  const gameRulesText = (
    <div>
      <h2>{i18n.t("wordScramble.gameRules.welcome")}</h2>
      <p>{i18n.t("wordScramble.gameRules.goal")}</p>
      <h3>{i18n.t("wordScramble.gameRules.howToPlay")}</h3>
      <ul>
        <li>{i18n.t("wordScramble.gameRules.rule1")}</li>
        <li>{i18n.t("wordScramble.gameRules.rule2")}</li>
        <li>{i18n.t("wordScramble.gameRules.rule3")}</li>
        <li>{i18n.t("wordScramble.gameRules.rule4")}</li>
      </ul>
      <p>{i18n.t("wordScramble.gameRules.goodLuck")}</p>
    </div>
  );

  // Fetch a random word from the backend
  const fetchRandomWord = async () => {
    try {
      let url = "";

      const randomLength = Math.floor(Math.random() * (12 - 3 + 1)) + 3;

      if (language === "en") {
        setLanguage("en");
        url =
          MAPIURLMaker("games/getRandomGameWord") +
          `?lang=EN&length=${randomLength}`;
      } else {
        url = MAPIURLMaker("games/single-random-word", { language, level });
      }
      const response = await axios.get(url);
      const newWord = response.data; // Expecting a string directly
      console.log("Scramble random word:", newWord);

      if (!newWord) {
        throw new Error("Invalid word received from the backend.");
      }

      setOriginalWord(newWord);
      setShuffledWord(shuffleWord(newWord));
    } catch (error) {
      console.error("Error fetching random word:", error);
      setPopupMessage(i18n.t("wordScramble.error"));
    }
  };

  // Only fetch the word once when the component mounts
  useEffect(() => {
    fetchRandomWord(); // Call once on mount
  }, [language]); // Empty dependency array ensures this effect only runs once

  useEffect(() => {
      setInterfaceLanguage(language);
    }, [language]);

  const handleLanguageChange = (newLang) => {
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const handleInterfaceLanguageChange = (newLang) => {
    setInterfaceLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  /* const handleLevelChange = (newLevel) => {
    setLevel(newLevel);
    console.log("Selected Level:", newLevel);
    resetGame();
  }; */

  // Timer logic for the game
  useEffect(() => {
    if (gameStarted && timeLeft > 0 && !gameOver) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (gameStarted && timeLeft === 0) {
      setGameOver(true);
      setEndMessage(`${i18n.t("wordScramble.lose")} ${originalWord}`);
    }
  }, [gameStarted, timeLeft, gameOver]);

  const handleSubmitGuess = () => {
    if (!input) {
      setPopupMessage(i18n.t("wordScramble.invalid"));
      setTimeout(() => {
        setPopupMessage("");
      }, 1000);
      return;
    }
    const normalizedInput = input.toLowerCase();
    console.log("User submitted input - WORDSCRAMBLE:", normalizedInput);
    if (normalizedInput === originalWord) {
      setGameOver(true);
      setEndMessage(i18n.t("wordScramble.win"));
      setShowConfetti(true);
    } else {
      setFoundWords((prevFoundWords) => [...prevFoundWords, normalizedInput]);
      setPopupMessage(i18n.t("wordScramble.wrongGuess"));
      setTimeout(() => {
        setPopupMessage("");
      }, 1000);
      setInput(""); // Clear the input field on wrong guess
      inputRef.current?.focus();
    }
  };

  const handleRestart = () => {
    fetchRandomWord(); // Restart the game and fetch a new word
    setTimeLeft(120);
    setInput("");
    setEndMessage(null);
    setPopupMessage(null);
    setGameOver(false);
    setShowConfetti(false);
    setFoundWords([]);
    inputRef.current?.focus();
  };

  const handleShuffle = () => {
    setShuffledWord(shuffleWord(originalWord)); // Shuffle the word again
  };

  const handleKeyboardInput = (inputKey) => {
    if (inputKey === "{bksp}") {
      setInput((prev) => prev.slice(0, -1));
    } else if (inputKey === "{enter}") {
      const normalizedInput = input.toLowerCase();
      handleSubmitGuess(normalizedInput);
    } else {
      const correctedInput = handleSpecialCharacters(inputKey);
      setInput((prev) => prev + correctedInput);
    }
  };

  // Conversion for Turkish letters
  const handleSpecialCharacters = (inputKey) => {
    switch (inputKey) {
      case "I":
        return "ı";
      case "İ":
        return "i";
      case "Ç":
        return "ç";
      case "Ğ":
        return "ğ";
      case "Ö":
        return "ö";
      case "Ü":
        return "ü";
      case "Ş":
        return "ş";
      default:
        return inputKey.toLowerCase();
    }
  };

  // Focus on the input field once the game starts
  useEffect(() => {
    if (gameStarted) {
      inputRef.current?.focus();
    }
  }, [gameStarted]); // Dependency on gameStarted

  useEffect(() => {
    if (!gameOver && inputRef.current) {
      inputRef.current?.focus(); // Refocus the input if not game over
    }
  }, [input, gameOver]); // Trigger refocus when input changes

  const handleStartGame = () => {
    setGameStarted(true);
    inputRef.current?.focus();
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    // Backspace check
    if (e.key === "Backspace") {
      setInput((prev) => prev.slice(0, -1));
    }
    // Enter check
    else if (e.key === "Enter") {
      const normalizedInput = input.toLowerCase();
      handleSubmitGuess(normalizedInput);
    }
    // Accept letters only
    else if (/^[a-zA-ZğĞüÜşŞıİçÇöÖ]$/.test(e.key)) {
      // Special cases for Turkish characters
      let correctedKey = e.key;
      switch (correctedKey) {
        case "I":
          correctedKey = "ı";
          break;
        case "İ":
          correctedKey = "i";
          break;
        default:
          correctedKey = correctedKey.toLowerCase();
      }
      setInput((prev) => prev + correctedKey);
    }
  };

  const handleInfoButtonClick = () => {
    setShowGameRules(true); // Show the game rules popup
  };

  const handleClosePopup = () => {
    setShowGameRules(false); // Close the game rules popup
  };

  const resetGame = () => {
    setGameStarted(false);
    fetchRandomWord();
    setTimeLeft(120);
    setInput("");
    setEndMessage(null);
    setPopupMessage(null);
    setGameOver(false);
    setShowConfetti(false);
    inputRef.current?.focus();
  };

  return (
    <div style={{ textAlign: "center" }}>
      {/* React Helmet for Meta Tags */}
      <Helmet>
        <html lang={language} />
        <title>
          {language === "tr"
            ? "Karışık Kelimeler Oyunu - Sözlük ile Oyna ve Öğren"
            : "Word Scramble Game - Play and Learn with the Dictionary"}
        </title>
        <meta
          name="description"
          content={
            language === "tr"
              ? "Kelime bilginizi geliştirmek için bu eğlenceli kelime oyununu oynayın. Sözlük uygulamamızla yeni kelimeler öğrenin ve eğlenin!"
              : "Play this fun word game to improve your vocabulary. Learn new words and enjoy with our dictionary app!"
          }
        />
        <meta
          name="keywords"
          content={
            language === "tr"
              ? "kelime oyunu, sözlük oyunu, kelime öğrenme, kelime bulma"
              : "word game, dictionary game, learn words, find words"
          }
        />
        <meta property="og:type" content="game" />
        <meta
          property="og:title"
          content={
            language === "tr"
              ? "Kelime Oyunu - Sözlük ile Oyna ve Öğren"
              : "Word Game - Play and Learn with the Dictionary"
          }
        />
        <meta
          property="og:description"
          content={
            language === "tr"
              ? "Kelime bilginizi test edin ve sözlük uygulamamızla yeni kelimeler öğrenin."
              : "Test your word knowledge and learn new words with our dictionary app."
          }
        />
        <meta property="og:type" content="game" />
        <meta
          property="og:url"
          content="https://lamba.dev/games/wordscramble"
        />
        <meta
          property="og:image"
          content="https://lamba.dev/wordscramble-preview.png"
        />
      </Helmet>
      {!gameStarted && (
        <GameRules
          rulesText={gameRulesText}
          onStartGame={handleStartGame}
          /* onLevelChange={handleLevelChange} */
          onLanguageChange={handleLanguageChange}
        />
      )}

      {gameStarted && (
        <>
          {showConfetti && (
            <Confetti width={window.innerWidth} height={window.innerHeight} />
          )}
          {/* Title and Info Button */}
          <div style={{ margin: 0 }}>
            <button
              style={{
                background: "transparent",
                color: "#e78700",
                border: "none",
                padding: "0px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "30px",
                marginLeft: "10px",
              }}
              onClick={handleInfoButtonClick}
            >
              <h2 style={{ fontSize: "15px", marginRight: "5px" }}>
                {i18n.t("wordScramble.gameRules.howToPlay")}
              </h2>
              <HelpOutlineIcon
                style={{
                  borderRadius: "50%",
                  background: "#f7d759",
                  color: "#e78700",
                  fontSize: "20px",
                }}
              />
            </button>
          </div>
          <div
            className="info-field"
            style={{ display: "inline-block", alignItems: "center" }}
          >
            <h1 className="game-name">{i18n.t("wordScramble.name")}</h1>

            <div>
              <UILangSwitcher
                interfaceLanguage={interfaceLanguage}
                onInterfaceLanguageChange={handleInterfaceLanguageChange}
              />
            </div>
          </div>

          {popupMessage && <GameMessage message={popupMessage} />}

          {/* Game End Screen */}
          {endMessage && (
            <GameEndScreen
              message={endMessage}
              onRetry={handleRestart}
              buttonText={i18n.t("wordScramble.playAgain")}
            />
          )}

          {/* Scrambled Word and Game Controls */}
          <div className="randoms-container">
            <h3 className="randoms">
              {i18n.t("wordScramble.scrambledWord")} {shuffledWord}
            </h3>
          </div>

          <div
            className="flex-row"
            style={{ justifyContent: "center", width: "100%" }}
          >
            <div style={{ position: "relative" }}>
              <input
                ref={inputRef}
                className="input-field"
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={i18n.t("wordScramble.placeholder")}
                style={{
                  paddingRight: "30px", // Space for the X button
                }}
              />
              {input && ( // Show the X button only if there's input
                <button
                  className="input-delete"
                  onClick={() => setInput("")} // Clear the input
                  style={{
                    right: "10px", // Position the X button to the right
                  }}
                >
                  <div className="input-delete-icon">
                    <img src={deleteIcon} alt="Delete" />
                  </div>
                </button>
              )}
            </div>

            <button
              className="game-button"
              onClick={handleSubmitGuess}
              style={{ marginLeft: "10px" }}
            >
              {i18n.t("wordScramble.guess")}
            </button>
            <button
              className="game-button"
              onClick={handleShuffle}
              style={{ marginLeft: "10px" }}
            >
              {i18n.t("wordScramble.shuffle")}
            </button>
          </div>

          <p className="timer">
            {i18n.t("wordScramble.timeLeft", { timeLeft })}
          </p>
          <KeyboardComponent handleKeyboardInput={handleKeyboardInput} />
          <div className="chalkboard">
            <h3 className="chalkboard-title">
              {i18n.t("wordScramble.wrongGuesses")}
            </h3>
            <p className="found-words">{foundWords.join(", ")}</p>
          </div>
        </>
      )}

      {/* Popup for showing game rules */}
      {showGameRules && (
        <div className="game-rules-overlay">
          <div className="game-rules-content">
            <div className="rules-popup">
              {gameRulesText}
              <div className="button-container">
                <button
                  className="game-rules-button close-button"
                  onClick={handleClosePopup}
                >
                  {i18n.t("games.closeBtn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WordScrambleGame;
