import React, { useState } from 'react';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "../../styles/gamesContainer.css";
import { useTranslation } from "react-i18next";
import { use } from "i18next";

const KeyboardComponent = ({ handleKeyboardInput }) => {
  const { t, i18n } = useTranslation();
  const [fontSize, setFontSize] = useState("16px");
  const [buttonPadding, setButtonPadding] = useState("8px");
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(true);

  return (
    <div>
      <div className="settings flex-row">
        <label>
        {i18n.t("keyboard.font")}
          <input
            type="range"
            min="12"
            max="36"
            value={fontSize.replace("px", "")}
            onChange={(e) => setFontSize(`${e.target.value}px`)}
          />
        </label>
        {/* <label>
          Button Padding:
          <input
            type="range"
            min="4"
            max="16"
            value={buttonPadding.replace("px", "")}
            onChange={(e) => setButtonPadding(`${e.target.value}px`)}
          />
        </label> */}
        <label>
          <input
            type="checkbox"
            checked={isKeyboardVisible}
            onChange={() => setIsKeyboardVisible(!isKeyboardVisible)}
          />
          {i18n.t("keyboard.show")}
        </label>
      </div>
      {isKeyboardVisible && (
        <div
          className="keyboard-container"
          style={{
            "--keyboard-font-size": fontSize,
            "--keyboard-padding": buttonPadding,
          }}
        >
          <Keyboard
            onKeyPress={handleKeyboardInput}
            layout={{
              default: [
                "Q W E R T Y U I O P Ğ Ü",
                "A S D F G H J K L Ş İ",
                "Z X C V B N M Ö Ç {bksp}",
                "{enter}",
              ],
            }}
            display={{
              "{bksp}": "⌫",
              "{enter}": t("keyboard.enter"),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default KeyboardComponent;