import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ErrorMessage = ({ message }) => (
  <div>
    <div className="message-box error-message">
      <ErrorOutlineIcon
        style={{ marginRight: "8px", fontSize: "24px", color: "#b34848" }}
      />
      {message}
    </div>
  </div>
);

export default ErrorMessage;