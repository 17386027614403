import "../../styles/new.css";
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import MAPIURLMaker from "../MAPIURLMaker";
import APIURLMaker from "../APIURLMaker";
import { WordContext } from "../Contexts/WordContext";
import ErrorMessage from "../Common/ErrorMessage";
import { useTranslation } from 'react-i18next';

function DidYouMean({ word }) {
  const { t, i18n } = useTranslation();
  //const [rootWords, setRootWords] = useState([]);
  const [turkishResults, setTurkishResults] = useState([]);
  const [englishResults, setEnglishResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { setWord } = useContext(WordContext); // Use the setWord function from WordContext

  /*
  useEffect(() => {
    const fetchRootWords = async () => {
      if (!word) return; // Exit if no word is provided

      setLoading(true); // Set loading to true while fetching data
      setError(null); // Reset error state before fetching new data
      try {
        const URL = MAPIURLMaker(`check?word=${word}`); // Create API URL
        const response = await axios.get(URL); // Make GET request using Axios
        const validRootWords = response.data.filter(
          (rootWord) => rootWord.rootWord !== null,
        ); // Filter out entries where rootWord is null
        setRootWords(validRootWords); // Set valid root words from response data
      } catch (error) {
        console.error("Error fetching root words:", error); // Log the error to console
        setError(error); // Set error state if there is an error
      } finally {
        setLoading(false); // Set loading to false after data fetching
      }
    };

    fetchRootWords(); // Fetch root words when component mounts or word changes
  }, [word]);
*/

  useEffect(() => {
    const fetchTurkishandEnglishResults = async () => {
      if (!word) return; // Exit if no word is provided

      setLoading(true);
      setError(null);

      try {
        // Fetch from Turkish backend
        const turkishURL = MAPIURLMaker(`check?word=${word}`);
        const turkishResponse = await axios.get(turkishURL);
        //console.log("Turkish Response Data:", turkishResponse.data);
        //console.log("Turkish Response:", turkishResponse);
        const validTurkishResults = (turkishResponse?.data || []).filter(
          (result) => result.rootWord !== null
        ).map(result => ({
          rootWord: result.rootWord
        }));
        setTurkishResults(validTurkishResults);
      } catch (error) {
        console.error("Error fetching Turkish results:"+ error.message + error.stack );
        setTurkishResults([]); // Set to empty, but don't affect overall error state
      }

      try {
         /*
        const englishResponse = new Promise((resolve) => {
          const mockData = mockEnglishData[word] || [];
          setTimeout(() => resolve(mockData), 500); // Simulate network delay
        });*/
        // Fetch from English backend (POST request with word and distance parameters)
        const englishURL = APIURLMaker(`langs/findLangLikes`);
        const englishResponse = await axios.post(englishURL, {
          word: word,      // The word to check
          distance: 0.8,   // Adding the distance parameter
        });
        const validEnglishResults = (englishResponse.data?.lang_words || []).filter(
          (result) => result.lang_word !== null
        );
        setEnglishResults(validEnglishResults);
      } catch (error) {
        console.error("Error fetching English results:"+ error.message + error.stack);
        setEnglishResults([]); // Set to empty, but don't affect overall error state
      } finally {
        setLoading(false); // Loading is complete after both requests
      }
    };

    fetchTurkishandEnglishResults();
  }, [word]);

  if (loading) return null;

  if (turkishResults.length === 0 && englishResults.length === 0) return null;

  // Render root words in boxes
  return (
    
    <div className="fieldset flex-row">
      {/* Display Turkish results */}
      {turkishResults.length > 0 && (
        <div className="flex-row">
        <div className="legend">
        {t("main.DidYouMeanTR")}
          </div>
          <div className="flex-row" style={{ flexWrap: "wrap" }}>
            {turkishResults.slice(0, 10).map((rootWord, index) => (
              <div
                key={index}
                className="small-box quick-access-item root-word-box"
                id="root-word-box"
                onClick={() => setWord(rootWord.rootWord)} // Update word when a root word is clicked
              >
                {rootWord.rootWord}
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Display English results */}
      
      {englishResults.length > 0 && (
        <div className="flex-row">
        <div className="legend" >
        {t("main.DidYouMeanEN")}
          </div>
          <div className="flex-row" style={{ flexWrap: "wrap" }}>
            {englishResults.slice(0, 10).map((langWord, index) => (
              <div
                key={index}
                className="small-box root-word-box"
                id="root-word-box"
                onClick={() => setWord(langWord.lang_word)}
              >
                {langWord.lang_word}
              </div>
            ))}
          </div>
        </div>
      )}
      </div>
    
    
  );
}
export default DidYouMean;