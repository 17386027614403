import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import HistoryTodayFct from "../History/HistoryTodayFct";
import HistoryThisWeekFct from "../History/HistoryThisWeekFct";
import HistoryAllTimesFct from "../History/HistoryAllTimesFct";
import { Paper } from "@mui/material";
import ChampsAllTimesFct from "../Champs/ChampsAllTimesFct";
import { WordContext } from "../Contexts/WordContext";
import React, { useContext } from "react";
import Tutorial from "../Common/Tutorial";
import { useState, useRef } from "react";
import Dots2 from "../../Images/pattern-dots2.svg";
import { TutorialContext } from "../Common/TutorialContext";
import { useTranslation } from 'react-i18next';

//Fetch and display historical and champion related data
function HistoryColumn() {
  const { t, i18n } = useTranslation();
  const { word } = useContext(WordContext);

  // State to track the current tutorial step and visibility
  const {
    currentStep,
    tutorialVisible,
    handleNextStep,
    handleCloseTutorial,
    currentFocusName,
  } = useContext(TutorialContext);

  // Ref for the components (for tutorial)
  const historyRef = useRef(null);

  var content = (
    <Paper
      className="main-container"
      sx={{
        backgroundImage: `url(${Dots2})`,
        backgroundSize: "100em", // Set to 'auto' or a specific size for repeating
        backgroundRepeat: "repeat", // Options: 'repeat', 'repeat-x', 'repeat-y', 'no-repeat'
        backgroundPosition: "top left", // Starting position of the background image
      }}
      elevation={3}
    >
      {/*<ChampsAllTimesFct />*/}
      <HistoryTodayFct word={word} />
      <div ref={historyRef}>
      
      <HistoryThisWeekFct />
        <HistoryAllTimesFct />
      </div>
      {tutorialVisible &&
        currentFocusName === "HistoryColumn" &&
        currentStep === 1 && (
          <Tutorial
            refElement={historyRef}
            step={1}
            currentStep={currentStep}
            message={t("tutorial.History1")}
            onNext={handleNextStep}
            onClose={handleCloseTutorial}
          />
        )}
    </Paper>
  );

  return content;
}

export default HistoryColumn;