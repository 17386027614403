import MeaningItemFct from "./MeaningItemFct.js";
import React, { useState } from "react";
import LoadingIndicator from "../Common/LoadingIndicator.js";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { useTranslation } from 'react-i18next';

// Display a chapter's name, syllables with bullet points, and a list of meanings
function ChapterCompFct(props) {
  const { t, i18n } = useTranslation();
 /*  const [isExpanded, setIsExpanded] = useState(true); */ // State to manage expansion
  const name = props.name; // Extract the chapter name from props
  const syls = props.syls; // Extract the syllables from props
  const slysNew = syls.replaceAll(".", String.fromCharCode(8226)); // Replace periods with bullet points in syllables
  const meanings = props.meanings; // Extract the meanings array from props
  const loading = props.loading;
  const hideLangPockets = props.hideLangPockets;
  const isExpanded = props.isExpanded;

  //console.log("Loading : " + props.loading);
  // Toggle the expanded/collapsed state
 /*  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  }; */

 

  // Fake loading delay
  /*useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000); // 3 second delay
  }, []);*/

  // Create a list of MeaningItemFct components for each meaning
  const meaningList = meanings.map((item) => (
    <MeaningItemFct
      key={item.id}
      data={item}
      hideLangPockets={hideLangPockets}
    />
  ));
  return (
    <div className="title">
      
      {/* Show loading spinner if loading is true */}
      {loading ? (
        <LoadingIndicator loading={loading} />
      ) : (
        <>
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {name} [ {slysNew} ]
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          

         {/*  <div
            onClick={toggleExpand}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isExpanded ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
          </div> */}
        </div>
      </div>
      
        {isExpanded && <div>{meaningList}</div>}
        </>
      )}
      <div style={{ marginBottom: "10px" }}></div>
    </div>
  );
}

export default ChapterCompFct;