import React from "react";

/* This component displays a clickable item for search results.
 It adjusts the background color based on a calculated HSL value from the distance prop and
  allows clicking to trigger a search with the displayed word.  */
const SearchResultItem = (props) => {
  // Check if props are undefined, render "Nothing" if true
  if (typeof props === "undefined") {
    return <div>Nothing</div>;
  } else {
    const { word, distance, setSearchWord } = props; // Destructure props to get word, distance, and setSearchWord function
    //console.log("word:", word, "distance:", distance);

    let index100 = distance * 100;

    // Saturation will get closer to 0 as the index decreases (white)
    let saturation = index100 > 98 ? 100 : 
                     index100 > 95 ? 80 :
                     index100 > 90 ? 60 :
                     index100 > 80 ? 40 : 0;
    
    // Lightness
    let lightness = index100 > 98 ? 50 :
                    index100 > 95 ? 60 :
                    index100 > 90 ? 70 :
                    index100 > 80 ? 85 : 100;
    
    let hslValue = `hsl(40, ${saturation}%, ${lightness}%)`;
    
    
    
    return (
      <div
        className="small-box quick-access-item"
        style={{
          backgroundColor: hslValue, // Set background color dynamically based on hslValue
        }}
        title={word} // Show word as title on hover
        onClick={() => setSearchWord(word)} // Call setSearchWord function with word onClick
      >
        {word} {/* Display the word */}
      </div>
    );
  }
};

export default SearchResultItem;