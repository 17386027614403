import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Confetti from "react-confetti";
import MAPIURLMaker from "../MAPIURLMaker";
import axios from "axios";
import KeyboardComponent from "./KeyboardComponent";
import GameRules from "./GameRules";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import LoadingIndicator from "../Common/LoadingIndicator";
import GameMessage from "./GameMessage";
import GameEndScreen from "./GameEndScreen";
import UILangSwitcher from "./UILangSwitcher";

function Wordle() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("tr");
  const [interfaceLanguage, setInterfaceLanguage] = useState("tr");
  const [level, setLevel] = useState("impossible");
  const [inputRows, setInputRows] = useState(Array(6).fill(Array(5).fill("")));
  const [currentRow, setCurrentRow] = useState(0);
  const [currentLetter, setCurrentLetter] = useState(0);
  const [randomWord, setRandomWord] = useState("");
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [feedback, setFeedback] = useState(Array(6).fill(Array(5).fill(""))); // For feedback (correct, exists, incorrect)
  const [popupMessage, setPopupMessage] = useState(null);
  const [endMessage, setEndMessage] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [showGameRules, setShowGameRules] = useState(false);
  const [loading, setLoading] = useState(false);

  // Define game rules
  const gameRulesText = (
    <div>
      <h2>{i18n.t("wordle.gameRules.welcome")}</h2>
      <p>{i18n.t("wordle.gameRules.goal")}</p>
      <h3>{i18n.t("wordle.gameRules.howToPlay")}</h3>
      <ul>
        <li>{i18n.t("wordle.gameRules.guessWord")}</li>
        <li>
          {i18n.t("wordle.gameRules.feedbackExplanation")}
          <ul>
            <li>
              <strong style={{ color: "#6aaa64" }}>
                {i18n.t("wordle.gameRules.strongGreen")}
              </strong>
              {i18n.t("wordle.gameRules.feedbackGreen")}
            </li>
            <li>
              <strong style={{ color: "#c9b458" }}>
                {i18n.t("wordle.gameRules.strongYellow")}
              </strong>
              {i18n.t("wordle.gameRules.feedbackYellow")}
            </li>
            <li>
              <strong style={{ color: "#787c7e" }}>
                {i18n.t("wordle.gameRules.strongGrey")}
              </strong>
              {i18n.t("wordle.gameRules.feedbackGrey")}
            </li>
          </ul>
        </li>
      </ul>
      <p>{i18n.t("wordle.gameRules.goodLuck")}</p>
    </div>
  );

  // Function to fetch random word from backend
  const getRandomWord = async () => {
    try {
      let url = "";

      if (language === "en") {
        setLanguage("en");
        url = MAPIURLMaker("games/getRandomGameWord") + `?lang=EN&length=5`;
      } else {
        url = MAPIURLMaker("games/random-five-letter-word", {
          language,
          level,
        });
      }
      const response = await fetch(url);
      if (!response.ok) {
        console.log("Wordle- error fetching random word");
        throw new Error("Network response was not ok");
      }
      const data = await response.text(); // random word is returned as a string
      console.log("Wordle random word:", data);
      setRandomWord(data);
    } catch (error) {
      console.error("Error fetching random word:", error);
    }
  };

  // Fetch random word when the component mounts
  useEffect(() => {
    getRandomWord();
  }, [language]);

  useEffect(() => {
      setInterfaceLanguage(language);
    }, [language]);

  const handleLanguageChange = (newLang) => {
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const handleInterfaceLanguageChange = (newLang) => {
    setInterfaceLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const handleLevelChange = (newLevel) => {
    setLevel(newLevel);
    console.log("Selected Level:", newLevel);
    resetGame();
  };

  const resetGame = () => {
    setGameStarted(false);
    setInputRows(Array(6).fill(Array(5).fill("")));
    setCurrentRow(0);
    setCurrentLetter(0);
    setFeedback(Array(6).fill(Array(5).fill("")));
    setGameOver(false);
    setGameWon(false);
    setShowConfetti(false);
    setPopupMessage(null);
    setEndMessage(null);
    getRandomWord();
  };

  // Function to give feedback on the guessed word
  const getFeedback = (guessedWord) => {
    const feedbackArray = [];
    const randomWordArr = randomWord.split("");

    try {
      for (let i = 0; i < 5; i++) {
        if (guessedWord[i] === randomWordArr[i]) {
          feedbackArray.push("correct");
        } else if (randomWordArr.includes(guessedWord[i])) {
          feedbackArray.push("exists");
        } else {
          feedbackArray.push("incorrect");
        }
      }
    } catch (error) {
      console.error("Error pushing feedback to feedbackArray:", error);
      console.log("guessedWord:", guessedWord);
      console.log("randomWord:", randomWord);
      console.log("feedbackArray so far:", feedbackArray);
    }

    return feedbackArray;
  };

  const handleStartGame = () => {
    setGameStarted(true);
  };

  // Handle input change (on typing)
  const handleInputChange = (e, rowIndex, colIndex) => {
    const value = handleSpecialCharacters(e.target.value);

    if (value.length === 1) {
      // Update the state for the specific cell
      setInputRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[rowIndex] = [...newRows[rowIndex]]; // Create a new copy of the row
        newRows[rowIndex][colIndex] = value;
        return newRows;
      });

      // Move to the next letter if it's not the last column
      if (colIndex < 4) {
        setCurrentLetter((prev) => prev + 1);
        // Automatically focus the next input box
        setTimeout(() => {
          const nextInput = document.querySelector(
            `#input-${rowIndex}-${colIndex + 1}`,
          );
          if (nextInput) nextInput.focus();
        }, 0); // Use timeout to ensure the state update is done before moving focus
      }
    }
  };

  // Handle backspace (on deleting a letter)
  const handleBackspace = () => {
    const row = currentRow;
    const col = currentLetter;

    // Check if the current box is empty
    if (inputRows[row][col] !== "") {
      // If not empty, delete the content and keep focus on the current box
      setInputRows((prevRows) => {
        const newRows = [...prevRows];
        newRows[row] = [...newRows[row]];
        newRows[row][col] = ""; // Clear the content
        return newRows;
      });

      // Keep focus on the same box
      setTimeout(() => {
        const currentInput = document.querySelector(`#input-${row}-${col}`);
        if (currentInput) currentInput.focus();
      }, 0);
    } else {
      // If the current box is empty, move to the previous box and delete content there
      if (currentLetter > 0) {
        const prevCol = currentLetter - 1;

        setInputRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[row] = [...newRows[row]];
          newRows[row][prevCol] = ""; // Clear the content of the previous box
          return newRows;
        });

        setCurrentLetter((prev) => prev - 1); // Move to the previous box

        // Set focus on the previous box
        setTimeout(() => {
          const prevInput = document.querySelector(`#input-${row}-${prevCol}`);
          if (prevInput) prevInput.focus();
        }, 0);
      }
    }
  };

  // Handle submit (when the user presses enter or clicks submit)
  const handleSubmit = async () => {
    const currentWord = inputRows[currentRow].join("");
    console.log("Current guessed word:", currentWord);

    if (currentWord.length === 5) {
      setLoading(true);
      try {
        let response;
        if (language === "en") {
          response = await axios.get(MAPIURLMaker("games/checkENWord"), {
            params: {
              word: currentWord.toLowerCase(),
              lang: "EN",
            },
          });
        } else {
          response = await axios.get(MAPIURLMaker("games/check-input"), {
            params: { word: currentWord.toLowerCase() },
          });
        }

        console.log("Backend response:", response.data);

        if (response.data === true || response.data === "true") {
          // Word exists, proceed with feedback and game logic
          const newFeedback = getFeedback(currentWord);

          // Update feedback for the current row
          setFeedback((prevFeedback) => {
            const newFeedbackArray = [...prevFeedback];
            newFeedbackArray[currentRow] = newFeedback;
            return newFeedbackArray;
          });

          // Check if the word matches the random word
          if (currentWord === randomWord) {
            setGameWon(true);
            setGameOver(true);
            setEndMessage(i18n.t("wordle.win"));
            setShowConfetti(true);
          } else {
            // Move to the next row only if it's not the last guess
            if (currentRow < 5) {
              setCurrentRow((prev) => prev + 1);
              setCurrentLetter(0); // Reset letter position for next row
            } else {
              setGameOver(true); // Game over if we run out of guesses
              setEndMessage(`${i18n.t("wordle.lose")} ${randomWord}`);
            }
          }
        } else {
          // Word does not exist, show popup message
          setPopupMessage(i18n.t("wordle.invalidWord"));
          setTimeout(() => {
            setPopupMessage(null);
          }, 2000);
        }
      } catch (error) {
        console.error("Error checking word:", error);
        setPopupMessage(i18n.t("wordle.error"));
        setTimeout(() => setPopupMessage(null), 1000);
      } finally {
        setLoading(false);
      }
    }
  };

  // Turkish letter conversion function
  const handleSpecialCharacters = (inputKey) => {
    switch (inputKey) {
      case "I":
        return "ı";
      case "İ":
        return "i";
      case "Ç":
        return "ç";
      case "Ğ":
        return "ğ";
      case "Ö":
        return "ö";
      case "Ü":
        return "ü";
      case "Ş":
        return "ş";
      default:
        return inputKey.toLowerCase();
    }
  };

  // Handle keyboard input for virtual keyboard
  const handleKeyboardInput = (inputKey) => {
    if (inputKey === "{bksp}") {
      handleBackspace();
    } else if (inputKey === "{enter}") {
      handleSubmit();
    } else {
      const convertedKey = handleSpecialCharacters(inputKey);
      if (currentLetter < 5) {
        setInputRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[currentRow] = [...newRows[currentRow]];
          newRows[currentRow][currentLetter] = convertedKey;
          return newRows;
        });

        // Move to the next cell
        if (currentLetter < 4) {
          setCurrentLetter(currentLetter + 1);
        }
      }
    }
  };

  // Focus on the current input box
  useEffect(() => {
    if (!gameOver && currentRow < 6) {
      const inputBox = document.querySelector(
        `.input-box[data-row="${currentRow}"][data-col="${currentLetter}"]`,
      );
      if (inputBox) {
        inputBox.focus();
      }
    }
  }, [currentRow, currentLetter, gameOver]);

  // Handle Enter key to submit the word
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleInfoButtonClick = () => {
    setShowGameRules(true); // Show the game rules popup
  };

  const handleClosePopup = () => {
    setShowGameRules(false); // Close the game rules popup
  };

  const handleDelete = () => {
    // Make sure the currentRow is valid
    if (currentRow >= 0 && currentRow < inputRows.length) {
      // Clear the entire current row
      const updatedRows = [...inputRows];
      updatedRows[currentRow] = ["", "", "", "", ""]; // Empty out all letters in the current row

      setInputRows(updatedRows); // Update the state with the cleared row
      setCurrentLetter(0); // Reset the cursor to the beginning of the row
    }
  };

  return (
    <div>
      {/* React Helmet for Meta Tags */}
      <Helmet>
        <html lang={language} />
        <title>
          {language === "tr"
            ? "Kelime Avı - Sözlük ile Oyna ve Öğren"
            : "Wordle Game - Play and Learn with the Dictionary"}
        </title>
        <meta
          name="description"
          content={
            language === "tr"
              ? "Kelime bilginizi geliştirmek için bu eğlenceli kelime oyununu oynayın. Sözlük uygulamamızla yeni kelimeler öğrenin ve eğlenin!"
              : "Play this fun word game to improve your vocabulary. Learn new words and enjoy with our dictionary app!"
          }
        />
        <meta
          name="keywords"
          content={
            language === "tr"
              ? "kelime oyunu, sözlük oyunu, kelime öğrenme, kelime bulma"
              : "word game, dictionary game, learn words, find words"
          }
        />
        <meta property="og:type" content="game" />
        <meta
          property="og:title"
          content={
            language === "tr"
              ? "Kelime Oyunu - Sözlük ile Oyna ve Öğren"
              : "Word Game - Play and Learn with the Dictionary"
          }
        />
        <meta
          property="og:description"
          content={
            language === "tr"
              ? "Kelime bilginizi test edin ve sözlük uygulamamızla yeni kelimeler öğrenin."
              : "Test your word knowledge and learn new words with our dictionary app."
          }
        />
        <meta property="og:type" content="game" />
        <meta property="og:url" content="https://lamba.dev/games/wordle" />
        <meta
          property="og:image"
          content="https://lamba.dev/wordle-preview.png"
        />
      </Helmet>
      {!gameStarted && (
        <GameRules
          rulesText={gameRulesText}
          onStartGame={handleStartGame}
          onLevelChange={handleLevelChange}
          onLanguageChange={handleLanguageChange}
        />
      )}

      {gameStarted && (
        <>
          {showConfetti && (
            <Confetti width={window.innerWidth} height={window.innerHeight} />
          )}
          {popupMessage && <GameMessage message={popupMessage} />}

          {/* Game End Screen */}
          {endMessage && (
            <GameEndScreen
              message={endMessage}
              onRetry={resetGame}
              buttonText={i18n.t("wordle.newGame")}
            />
          )}
          <div className="box">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: "15px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "transparent",
                  color: "#e78700",
                  border: "none",
                  padding: "0px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "30px",
                  marginLeft: "10px",
                }}
                onClick={handleInfoButtonClick}
              >
                <h2 style={{ fontSize: "15px", marginRight: "5px" }}>
                  {i18n.t("wordle.gameRules.howToPlay")}
                </h2>
                <HelpOutlineIcon
                  style={{
                    borderRadius: "50%",
                    background: "#f7d759",
                    color: "#e78700",
                    fontSize: "20px",
                  }}
                />
              </button>
            </div>

            <div
              className="info-field"
              style={{ display: "inline-flex", alignItems: "center" }}
            >
              <h1 className="game-name">{i18n.t("wordle.name")}</h1>
            </div>

            <div>
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <UILangSwitcher
                  interfaceLanguage={interfaceLanguage}
                  onInterfaceLanguageChange={handleInterfaceLanguageChange}
                />
              </div>

              <div
                style={{
                  fontSize: "0.8em",
                  color: "#777",
                  marginTop: "2px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start", // Ensures centering if needed
                  marginBottom: "4px",
                }}
              >
                <div
                  className="wordle-info"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1px", // Adds spacing between rows
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#6aaa64", // Green
                      margin: "1px 5px 1px 1px",
                      borderRadius: "3px",
                    }}
                  ></span>
                  {i18n.t("wordle.infoGreen")}
                </div>
                <div
                  className="wordle-info"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: "1px", // Adds spacing between rows
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#c9b458", // Yellow
                      margin: "1px 5px 1px 1px",
                      borderRadius: "3px",
                    }}
                  ></span>
                  {i18n.t("wordle.infoYellow")}
                </div>
                <div
                  className="wordle-info"
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#787c7e", // Gray
                      margin: "1px 5px 1px 1px",
                      borderRadius: "3px",
                    }}
                  ></span>
                  {i18n.t("wordle.infoGrey")}
                </div>
              </div>
            </div>

            <div className="wrapper">
              <div className="container">
                {inputRows.map((row, rowIndex) => (
                  <div key={rowIndex} className="input-group">
                    {row.map((letter, colIndex) => (
                      <input
                        key={colIndex}
                        id={`input-${rowIndex}-${colIndex}`} // Unique ID for each input
                        className={`input-box 
                    ${
                      feedback[rowIndex][colIndex] === "correct"
                        ? "correct"
                        : ""
                    } 
                    ${
                      feedback[rowIndex][colIndex] === "exists" ? "exists" : ""
                    } 
                    ${
                      feedback[rowIndex][colIndex] === "incorrect"
                        ? "incorrect"
                        : ""
                    }`}
                        value={letter}
                        onChange={(e) =>
                          handleInputChange(e, rowIndex, colIndex)
                        }
                        maxLength="1"
                        disabled={gameOver || rowIndex > currentRow}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") handleBackspace();
                          else handleKeyDown(e);
                        }}
                        onFocus={() => {
                          setCurrentRow(rowIndex);
                          setCurrentLetter(colIndex);
                        }}
                        autoFocus={
                          rowIndex === currentRow && colIndex === currentLetter
                        }
                        data-row={rowIndex}
                        data-col={colIndex}
                      />
                    ))}

                    {/* Only render the delete button or loading icon for the active row */}
                    {rowIndex === currentRow && (
                      <div className="delete-icon-container active">
                        {loading ? (
                          <LoadingIndicator
                            loading={true}
                            variant="delete-icon"
                            color="#f7d759"
                          />
                        ) : (
                          <DeleteForeverIcon
                            style={{
                              color: "#f7d759",
                              fontSize: "28px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(rowIndex)}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <button
                className={`game-button ${gameOver ? "hide" : ""}`}
                onClick={handleSubmit}
              >
                {i18n.t("wordle.submit")}
              </button>
            </div>
          </div>
          <div className="keyboard-container">
            <KeyboardComponent handleKeyboardInput={handleKeyboardInput} />
          </div>
        </>
      )}
      {/* Popup for showing game rules */}
      {showGameRules && (
        <div className="game-rules-overlay">
          <div className="game-rules-content">
            <div className="rules-popup">
              {gameRulesText}
              <div className="button-container">
                <button
                  className="game-rules-button close-button"
                  onClick={handleClosePopup}
                >
                  {i18n.t("games.closeBtn")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Wordle;
