import React from "react";
import { useTranslation } from "react-i18next";
import "../../styles/gamesContainer.css";

function LanguageSwitcher({ language, onLanguageChange }) {
  const { t } = useTranslation();

  return (
    <div className="language-switcher flex-row">
      <label className="language-switch" htmlFor="language-switch">
        {t("selectLanguage")}:&nbsp;
      </label>
      <select
        id="language-switch"
        value={language}
        onChange={(e) => onLanguageChange(e.target.value)}
      >
        <option value="tr">{t("languageTurkish")}</option>
        <option value="en">{t("languageEnglish")}</option>
      </select>
    </div>
  );
}

export default LanguageSwitcher;