import React from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { CircularProgress } from "@mui/material";

function LoadingIndicator({ loading, size = "large", color = "#D4AC0D", variant = "default", }) {
  if (variant === "delete-icon") {
    
    return (
      <CircularProgress
        size={30}
        sx={{ color }}
      />
    );
  }
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "50px",
      }}
    >
      <LoadingButton
        size={size}
        loading={loading}
        variant="text"
        disabled
        sx={{ color: color }}
        loadingIndicator={
          <CircularProgress
            size={30}
            sx={{ color: color }}
          />
        }
      >
        Loading
      </LoadingButton>
    </Box>
  );
}

export default LoadingIndicator;
/*
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

function LoadingIndicator() {
  return (
    <Box>
      <Skeleton variant="text" height={30} width={200} sx={{ borderRadius: '10px' }}/>
      <Skeleton variant="rectangular" height={40} sx={{ borderRadius: '10px' }}/>
      <Skeleton variant="text" height={30} width={200} sx={{ borderRadius: '10px' }}/>
      <Skeleton variant="rectangular" height={40} sx={{ borderRadius: '10px' }}/>
      <Skeleton variant="text" height={30} width={200} sx={{ borderRadius: '10px' }}/>
      <Skeleton variant="rectangular" height={40} sx={{ borderRadius: '10px' }}/>
    </Box>
  );
}

export default LoadingIndicator;*/
