import React, { useState, useEffect, useContext } from "react";
import axios from "axios"; // Import Axios
import APIURLMaker from "../../APIURLMaker";
import SearchResultItem from "./SearchResultItem";
import { WordContext } from "../../Contexts/WordContext";
import ErrorMessage from "../../Common/ErrorMessage";
import NoDataMessage from "../../Common/NoDataMessage";
import LoadingIndicator from "../../Common/LoadingIndicator";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from 'react-i18next';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import trFlag from "../../../Images/tr-flag.png";
import ukFlag from "../../../Images/gb-flag.png";

// Handles fetching and displaying search results based on a provided word
function ShowWordLikesFct() {
  const { t, i18n } = useTranslation();
  const { word, setWord } = useContext(WordContext); // Access current word and setter function from context
  const [loading, setLoading] = useState(false); // State to track loading state
  const [error, setError] = useState(null); // State to hold error information
  const [data, setData] = useState(null); // State to hold fetched data
  const [isExpanded, setIsExpanded] = useState(true); // State for foldable functionality
  const [visibleCount, setVisibleCount] = useState(9); // State to track the number of visible results
  const [isAllVisible, setIsAllVisible] = useState(false);
  const [flagType, setFlagType] = useState("TR");

  useEffect(() => {
    const fetchTRData = async () => {
      //Async function to fetch data
      setLoading(true);
      var URL;
      try {
        URL = APIURLMaker(`words/findLikes`); // Create API URL for fetching similar words
        const body = { word: `${word}`, benchmark: 0.9 }; // Request body containing the word and benchmark
        const response = await axios.post(URL, body); // Perform POST request to fetch similar words
        // Check if we got results from the Turkish endpoint
        if (response.data && response.data.length > 0) {
          setData(response.data); // Set Turkish data if found
          setFlagType("TR");
        } else {
          // If no results, fetch English data
          await fetchEngData();
        }
      } catch (error) {
        //console.log("error:" + error.message + error.stack);
        setError(error);
      } finally {
        setLoading(false); // Set loading to false regardless of outcome
      }
    };

    const fetchEngData = async () => {
      setLoading(true);
      try {
        const URL = APIURLMaker(`langs/findWordsForLangWord`);
        const body = { lang_word: word };
        const response = await axios.post(URL, body);

        const sortedData = response.data
          ? response.data.sort((a, b) => 
            a.word.localeCompare(b.word, 'tr', {sensitivity: 'base'})
        )
          : [];

        setData(sortedData);
        setFlagType("EN-TR");
      } catch (error) {
        console.error("Error fetching English data:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    setVisibleCount(9);
    setIsAllVisible(false);

    fetchTRData(); // Call fetchData function when component mounts or word changes

    // Cleanup function (optional)
    return () => {
      // Cancel pending requests, cleanup timers, etc.
    };
  }, [word]); // Depend on 'word' to refetch data whenever it changes

  const toggleExpand = () => setIsExpanded(!isExpanded); // Toggle function

  const showAllResults = () => {
    setVisibleCount(data.length);
    setIsAllVisible(true);
  };

  const showMoreResults = () => {
    setVisibleCount((prevCount) => Math.min(prevCount + 9, data.length));
    setIsAllVisible(false); // Ensure it doesn't mark as "All Visible" during incremental loading
  };

  const goToFirstResults = () => {
    setVisibleCount(9);
    setIsAllVisible(false);
  };

  const showLessResults = () => {
    setVisibleCount((prevCount) => Math.max(prevCount - 9, 9));

    // Stay in the "less results" state if still displaying more than the initial 15
    if (visibleCount - 9 > 9) {
      setIsAllVisible(true); // Keep "See Less" and "Go Back" visible
    } else {
      setIsAllVisible(false); // Reset if returning to initial 15
    }
  };

  // Conditional rendering based on word, loading state, error, and fetched data
  if (word !== null && word !== "") {
    if (loading) return <LoadingIndicator loading={loading} />; // Show loading message while fetching data
    if (error) {
      //console.log("error:" + error.message + error.stack);
      return (
        <ErrorMessage message={t("quickAccess.error")} />
      ); // Display error message and stack trace if an error occurs
    }

    return (
      <fieldset className="fieldset flex-row">
        <div
          className="legend"
          style={{ cursor: "pointer" }}
          onClick={toggleExpand}
        >
          {t("main.QuickAccess")}
        </div>
        <div style={{ display: "flex", justifyContent: "center"}}>
          {isExpanded ? (
            <KeyboardDoubleArrowUpIcon onClick={toggleExpand} color="primary"/>
          ) : (
            <KeyboardDoubleArrowDownIcon onClick={toggleExpand} color="primary"/>
          )}
        </div>
        
        <div className="flags-container" >
              {flagType === "TR" && (
                <div className="flags-container flex-row">
                <img 
                src={trFlag}
                alt="Turkey Flag" 
                style={{ height: "22px", fontSize: "30px" }} />
                </div>
              )}
              </div>
              {flagType === "EN-TR" && (
                <>
                <div className="flags-container ">
                  <img 
                  src={ukFlag}
                  alt="UK Flag" 
                  style={{ height: "15px", width: "25px", fontSize: "30px" }} />
                  </div>
                  <div className="flags-container " >
                  <ArrowRightAltIcon color="primary" /></div>
                  <div className="flags-container ">
                  <img 
                  src={trFlag}
                  alt="Turkey Flag" 
                  style={{ height: "22px", fontSize: "30px" }} />
                  </div>
                </>
              )}
            
            <div className="margin-all"></div>
        {isExpanded && ( // Conditional rendering of content
          <div className="flex-row" style={{ flexWrap: "wrap" }}>
            
            {data !== null && data.length > 0 ? (
              <>
                {data.slice(0, visibleCount).map((item, index) => (
                  <SearchResultItem
                    key={index}
                    word={item.word}
                    distance={item.distance}
                    setSearchWord={setWord}
                  />
                ))}
                {!isAllVisible && visibleCount < data.length && (
                  <>
                  <Tooltip title={t("main.SeeMore")} arrow>
                    <KeyboardArrowDownIcon
                      className="see-more-button"
                      color="primary"
                      onClick={showMoreResults}
                    />
                  </Tooltip>
                  <Tooltip title={t("main.SeeAll")} arrow>
                    <KeyboardDoubleArrowDownIcon
                      className="see-more-button"
                      color="primary"
                      onClick={showAllResults}
                    />
                  </Tooltip>
                </>
                )}
                {(visibleCount >= data.length || isAllVisible) && (
                  <>
                  <Tooltip title={t("main.SeeLess")} arrow>
                    <KeyboardArrowUpIcon
                      className="see-less-button"
                      color="primary"
                      onClick={showLessResults}
                    />
                  </Tooltip>
                  <Tooltip title={t("main.GoToFirst")} arrow>
                    <KeyboardDoubleArrowUpIcon
                      className="see-less-button"
                      color="primary"
                      onClick={goToFirstResults}
                    />
                  </Tooltip>
                </>
                )}
              </>
            ) : (
              <div className="mid-font">
                <NoDataMessage message={t("quickAccess.noData")} />
              </div>
            )}
          </div>
        )}
      </fieldset>
    );
  } else {
    return (
      <div className="mid-font">
        <NoDataMessage message={t("chapterBlock.noData")} />
      </div>
    );
  }
}

export default ShowWordLikesFct;