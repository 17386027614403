import "../../styles/new.css";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios"; // Import Axios
import ChapterBlockFct from "./ChapterBlockFct";
import Search from "@mui/icons-material/Search";
import SaveIcon from "@mui/icons-material/Save";
import { LangContext } from "../Contexts/LangContext";
import { WordContext } from "../Contexts/WordContext";
import { UserContext } from "../Contexts/UserContext";
import ShowWordLikesFct from "../SearchResults/QuickAccess/ShowWordLikesFct";
import WordMorphology from "../Analysis/WordMorphology";
import APIURLMaker from "../APIURLMaker";
import Swal from "sweetalert2";
import DidYouMean from "../SearchResults/DidYouMean";
import QuoteFct from "./QuoteFct";
import Tutorial from "../Common/Tutorial";
import { TutorialContext } from "../Common/TutorialContext";
import { useTranslation } from "react-i18next";
import BackspaceIcon from "@mui/icons-material/Backspace";
import trFlag from "../../Images/tr-flag.png";
import ukFlag from "../../Images/gb-flag.png";
import deleteIcon from "../../Images/delete_message.png";



// Helper function to generate dynamic meta tags
const generateMetaTags = (word) => {
  return {
    title: `${word} lamba.dev | Türkçe-İngilizce Sözlük ve Eş Anlamlı Kelimeler`,
    description: `${word} ile ilgili eş anlamlı kelimeler, Türkçe morfolojik analiz ve İngilizce çeviri burada.`,
    content: `thesaurus, ${word}, eş anlamlı kelime, dictionary, ${word} kelimesinin eş anlamlısı, Türkçe-İngilizce çeviri, sözlük, benzer kelime`,
  };
};

// Helper function to generate JSON-LD structured data
const generateStructuredData = (word, lang1Pockets) => {
  // Flatten the nested structure into a single array of pockets
  const pockets = Object.values(lang1Pockets).flat();
  //console.log("Flattened pockets:", pockets);

  return {
    "@context": "https://schema.org",
    "@type": "DefinedTermSet",
    name: `Lamba Sözlük - ${word}`,
    url: `https://lamba.dev/search?query=${word}`,
    terms: pockets.map((pocket) => ({
      "@type": "DefinedTerm",
      name: pocket.lang_word || "N/A", // Fallback if lang_word is missing
      description: `Meaning ID: ${pocket.meaning_id || "N/A"}, Chapter ID: ${pocket.chapter_id || "N/A"}`,
      alternateName: pocket.owner ? `Owner: ${pocket.owner}` : undefined, // Only include if owner exists
    })),
  };
};



// Handle word search and save operations, fetch word-related data from an API, and display search results
function FullWordFct(props) {
  const { t, i18n } = useTranslation();
  // console.log('Render FullWordFct');
  const { word, setWord } = useContext(WordContext); // Destructuring word and setWord from WordContext
  const { lang1Pockets, initLang1Pockets } = useContext(LangContext); // Destructuring lang1Pockets and initLang1Pockets from LangContext
  const { user } = useContext(UserContext); // Destructuring user from UserContext
  const {
    currentStep,
    tutorialVisible,
    handleNextStep,
    handleCloseTutorial,
    currentFocusName,
  } = useContext(TutorialContext); // Tutorial context values

  const inputRef = useRef(null); // used to focus on the textbox upon load immediately
  const didYouMeanRef = useRef(null);
  const showWordLikesRef = useRef(null);
  const chapterBlockRef = useRef(null);
  const morphologyRef = useRef(null);
  const quotesRef = useRef(null);

  const [componentsLoaded, setComponentsLoaded] = useState(false);

  //console.log(lang1Pockets);
  useEffect(() => {
    const tutorialClosed = localStorage.getItem("tutorialClosed");
    if (tutorialClosed) {
      handleCloseTutorial();
    } else {
      // Automatically search for "alan" on first app load
    }
    setWord("alan");
  }, [setWord, handleCloseTutorial]);

  useEffect(() => {
    const fetchData = async () => {
      // Async function to fetch data
      try {
        const URL = APIURLMaker(`meaningLangs/findMeaningLangs`); // Creating API URL for fetching meaning
        const body = { word: `${word}`, lang: "EN" }; // Defining request body
        const response = await axios.post(URL, body); // Making a POST request using Axios
        initLang1Pockets(response.data); // Initializing lang1Pockets with fetched data
        setComponentsLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response) {
          // The request was made, and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("Response data:", error.response.data);
          if (error.response.data.exception_type === "RECORD_NOT_FOUND") {
            initLang1Pockets({}); // Handle the not found case
            console.info(
              "Record not found for : " + error.response.data.request_detail,
            );
          } else {
            console.error("Error fetching data:", error.response.data);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Request made but no response:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error message:", error.message);
        }
      }
    };

    if (word) {
      fetchData(); // Fetch data if word is defined
      inputRef.current.focus(); // Focus on the input field

      let tmpTextField = document.getElementById("txtWordSearch");
      if (tmpTextField.value !== word) {
        tmpTextField.value = word; // Update input field value if it doesn't match the current word
      }
    }

    // Cleanup function (optional)
    return () => {
      // Cleanup logic here if needed
    };
  }, [word, initLang1Pockets]);

  // Proceed to the next step when components are ready
  useEffect(() => {
    if (componentsLoaded && tutorialVisible && currentStep === 7) {
      handleNextStep();
      // Trigger the first step of the tutorial
    }
  }, [componentsLoaded, tutorialVisible, currentStep, handleNextStep]);

  // Function to handle the submit action
  function submit() {
    let tmpTextField = document.getElementById("txtWordSearch");
    const searchWord = tmpTextField.value.toLowerCase();
    if (searchWord === word) {
      // If the word is the same, forcefully reset the state to trigger `useEffect` again
      setWord("");
      setTimeout(() => setWord(searchWord), 0);
    } else {
      setWord(searchWord); // Set the word with the value from input field
    }
  }

  // Function to save lang1Pockets
  const saveLang1Pockets = async (data) => {
    try {
      const flattenedPockets = Object.values(data).flatMap((arr) => arr); // Flatten the data

      const fullData = {
        word: word,
        lang: "EN",
        owner: user.login_id,
        pockets: flattenedPockets, // Prepare the data to be saved
      };
      console.log("Sending : ", fullData);
      const URL = APIURLMaker("meaningLangs/saveMeaningLangs");
      const response = await axios.post(URL, fullData, {
        headers: {
          Authorization: `Bearer ${user.jwt_token}`,
        },
      });
      Swal.fire({
        title: "Saved ..",
        text: response.data.pockets.length + " pocket saved !",
        icon: "success",
        confirmButtonText: "OK",
      }); // Show success alert
      console.log("Response : ", response);
      console.log("Data sent successfully:", response.data);
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      }); // Show error alert
      console.error("Error saving data:", error);
    }
  };

  const metaTags = generateMetaTags(word);
  const structuredData = generateStructuredData(word, lang1Pockets);
  //console.log("Generated terms:", structuredData.terms);

  return (
    <div className="main-container grid">
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
        <meta name="content" content={metaTags.content} />
        {/* Inject JSON-LD structured data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div>
        {tutorialVisible &&
          currentFocusName === "FullWord" &&
          currentStep <= 6 && (
            <Tutorial
              step={currentStep}
              message={`Step ${currentStep} in FullWordFct tutorial`}
              onNext={handleNextStep}
            />
          )}
        {/* FullWordFct component content here */}
      </div>
      <fieldset className="fieldset">
        <div className="legend">{t("search.Text")}</div>
        <div className="flex-row">
          <div
            className="edit-box-container"
            style={{ position: "relative", marginRight: "5px", }}
          >
            <input
              id="txtWordSearch"
              ref={inputRef}
              className="edit-box margin-all"
              type="text"
              name="searchItem"
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" &&
                  event.target.id === "txtWordSearch"
                ) {
                  submit(); // Submit on pressing Enter
                }
              }}
              style={{
                paddingRight: "18%", // Space for the delete button
              }}
            />
            <button
              className="input-delete"
              onClick={() => {
                inputRef.current.value = ""; // Clear the text input
                setWord(""); // Clear the word state
                inputRef.current.focus(); // Focus back on the input
              }}
            >
              <div className="input-delete-icon" >
              <img src={deleteIcon} alt="Delete" />
              </div>
            </button>
          </div>
          {/* Flags */}
          <div className="flags-container ">
            <img
              src={trFlag}
              alt="Turkey Flag"
              style={{
                height: "22px",
                fontSize: "30px",
              }}
            />
          </div>
          <div className="margin-mini-2"></div>
          <div className="flags-container">
            <img
              src={ukFlag}
              alt="UK Flag"
              style={{ height: "15px", width: "25px", fontSize: "30px" }}
            />
          </div>

          <button
            className="toggle-button flex-row"
            style={{ paddingLeft: "20px" }}
            onClick={() => setWord(inputRef.current.value)}
            //submit(); // Submit on clicking Search button
          >
            <div className="margin-mini-2">{t("search.Button")}</div>
            <Search fontSize="small" /> {/* Search icon */}
          </button>
          {/*<button
            style={{ marginLeft: "auto", marginRight: "20px" }}
            className="btn-style-active flex-row"
            onClick={() => {
              saveLang1Pockets(lang1Pockets); // Save data on clicking Save button
            }}
          >
            <div className="margin-mini">Save</div>
            <SaveIcon fontSize="small" /> 
          </button>*/}
        </div>

        {/* Tutorial pop-up for the search bar */}
        {tutorialVisible &&
          currentFocusName === "FullWord" &&
          currentStep === 1 && (
            <Tutorial
              refElement={inputRef}
              step={1}
              currentStep={currentStep}
              message={t("tutorial.FullWord1")}
              onNext={handleNextStep}
              onClose={handleCloseTutorial}
            />
          )}
      </fieldset>

      {word !== "" && componentsLoaded && (
        <div>
          <div ref={didYouMeanRef}>
            <DidYouMean word={word} />
          </div>
          {tutorialVisible &&
            currentFocusName === "FullWord" &&
            currentStep === 2 && (
              <Tutorial
                refElement={didYouMeanRef}
                step={2}
                currentStep={currentStep}
                message={t("tutorial.FullWord2")}
                onNext={handleNextStep}
                onClose={handleCloseTutorial}
              />
            )}
          <div ref={showWordLikesRef}>
            <ShowWordLikesFct props={{ word, setWord }} />
          </div>
          {tutorialVisible &&
            currentFocusName === "FullWord" &&
            currentStep === 3 && (
              <Tutorial
                refElement={showWordLikesRef}
                step={3}
                currentStep={currentStep}
                message={t("tutorial.FullWord3")}
                onNext={handleNextStep}
                onClose={handleCloseTutorial}
              />
            )}

          <div ref={chapterBlockRef}>
            <ChapterBlockFct props={{ word: word }} />{" "}
            {/* Render chapter block */}
          </div>
          {tutorialVisible &&
            currentFocusName === "FullWord" &&
            currentStep === 4 && (
              <Tutorial
                refElement={chapterBlockRef}
                step={4}
                currentStep={currentStep}
                message={t("tutorial.FullWord4")}
                onNext={handleNextStep}
                onClose={handleCloseTutorial}
              />
            )}
          <div ref={morphologyRef}>
            <WordMorphology word={word} />
          </div>
          {tutorialVisible &&
            currentFocusName === "FullWord" &&
            currentStep === 5 && (
              <Tutorial
                refElement={morphologyRef}
                step={5}
                currentStep={currentStep}
                message={t("tutorial.FullWord5")}
                onNext={handleNextStep}
                onClose={handleCloseTutorial}
              />
            )}

          <div>
            <QuoteFct word={word} ref={quotesRef} />
          </div>
          {tutorialVisible &&
            currentFocusName === "FullWord" &&
            currentStep === 6 && (
              <Tutorial
                refElement={quotesRef}
                step={6}
                currentStep={currentStep}
                message={t("tutorial.FullWord6")}
                onNext={handleNextStep}
                onClose={handleCloseTutorial}
              />
            )}
        </div>
      )}
    </div>
  );
}

export default FullWordFct;
