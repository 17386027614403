import React, { useState, useEffect, useContext } from "react";
import Tabs from "./Tabs";
import axios from "axios";
import APIURLMaker from "../APIURLMaker";
import { WordContext } from "../Contexts/WordContext";
import ErrorMessage from "../Common/ErrorMessage";
import NoDataMessage from "../Common/NoDataMessage";
import LoadingIndicator from "../Common/LoadingIndicator";
import { useTranslation } from 'react-i18next';
import StaticContent from "../Common/StaticContent";


// Fetch related words based on a given word and display them in tabbed format
function YakinTabFct(params) {
  const { t, i18n } = useTranslation();
  const { word, setWord } = useContext(WordContext); // Destructure word and setWord from WordContext
  // console.log("Starting new YakinTabFct with word:" + word);

  // States
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true while fetching data
      try {
        const URL = APIURLMaker(`yakins/getYakins`); // Create URL for API request
        const body = { word: word }; // Prepare request body
        const response = await axios.post(URL, body); // Send POST request to the API
        setData(response.data); // Store response data in state
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        setError(error); // Set error state if request fails
        setLoading(false); // Set loading to false if request fails
      }
    };

    if (word) {
      fetchData(); // Fetch data if word is not null
    }

    // Cleanup function (optional)
    return () => {
      // Cancel pending requests, cleanup timers, etc.
    };
  }, [word]); // Dependency array for useEffect, runs when word changes

  if (loading) return <LoadingIndicator loading={loading} />; // Show loading message
  if (!word)
    return <div className="mid-font">{t("yakins.noWord")}</div>; // Prompt user to enter a word

  if (error) {
    return (
      <ErrorMessage message={t("yakins.error")} />
    ); // Show error message
  }

  if (data !== null && data.length > 0) {
    const types = new Set(data.map((item) => item.word_type)); // Get unique word types

    let lists = new Map(); // Initialize a Map to store sorted lists of words by type
    for (let item of types) {
      let res = data.filter((tmp) => tmp.word_type === item); // Filter words by type
      res.sort((a, b) => a.thes_order - b.thes_order); // Sort words by thes_order
      lists.set(item, res); // Add sorted list to the Map
    }

    return (
      <Tabs>
        {[...lists].map(([key, values]) => (
          <div label={key} key={key}>
            {values.map((item) => (
              <div
                key={item.id}
                className="word"
                style={{ backgroundColor: "#E0E0E0", flexGrow: 1 }}
              >
                <div style={{ flexGrow: 0.1 }} />
                {item.thes}
              </div>
            ))}
          </div>
        ))}
      </Tabs>
    ); // Render Tabs component with word lists as tab content
  } else {
    return <StaticContent src="/Chart1.png" alt="No data available" />; // Show static content if no data is found
  }
}

export default YakinTabFct;