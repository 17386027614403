import React from "react";
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import noDataImage from '../../Images/noDataImage.jpg';

const NoDataMessage = ({ message }) => (
    <div>
    {/* <div className="no-data-message-container">
    <img 
      src={noDataImage} 
      alt="No data available" 
      style={{ width: "150px", marginBottom: "16px" }}
    /> */}
    <div className="message-box no-data-message">
      <SpeakerNotesOffIcon
        style={{ marginRight: "8px", fontSize: "24px", color: "#6e6d6d" }}
      />
      {message}
    </div>
  </div>
);

export default NoDataMessage;